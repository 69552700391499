import React, { useState, useContext } from 'react';
import {
  addDoc,
  collection,
  type DocumentData,
  type CollectionReference,
  type QueryDocumentSnapshot,
} from 'firebase/firestore';

import { Modal } from '../../Modals';
import { db } from '../../../firebase-config';
import { toastNotification } from '../../../utils/Toast';
import { havePermission } from '../../../access/Permission';
import { UserContext } from '../../../contexts/UserContext';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { getCreateCourseSchema } from '../../../validation/Rules';
import { getMatchedDocument } from '../../../firestoredb/Queries';
import { handleChange, handleSubmission } from '../../../form/Handlers';
import { courseInputFieldMapper } from '../../../form/InputFieldMappers';
import type { ICourse, IFieldsForInput } from '../../../data-structure/Interfaces';
import type { EventTypes, TargetElementTypes } from '../../../data-structure/Types';
import {
  MEMBERSHIP_TYPES,
  ACCESS_ITEMS,
  COLLECTIONS,
  MODAL_SIZE,
  NOTIFICATIONS,
} from '../../../data-structure/Enums';

import './Create.scss';

const CreateCourse: React.FC = (): React.JSX.Element | null => {
  const { showCreateCourseModal, setCreateCourseModal } = useContext(GlobalContext);

  // Get user context data
  const { user } = useContext(UserContext);

  // Get access permission
  // Return if no course creation permission
  if (!havePermission(ACCESS_ITEMS.CreateOwnCourse, user?.userRole)) return null;

  // Input data and error states
  const [courseInput, setCourseInput] = useState<Partial<ICourse>>({});
  const [errorMessages, setErrorMessages] = useState<Partial<Record<keyof ICourse, string>>>({});

  // Course collection reference
  const coursesCollection: CollectionReference<DocumentData> = collection(db, COLLECTIONS.COURSES);

  // Get input field mapper
  const inputFields = courseInputFieldMapper();
  const courseSchema = getCreateCourseSchema();

  // On change callback
  const doChange = (e: EventTypes, error: string | null): void => {
    if ('target' in e) {
      const target = e.target as TargetElementTypes;

      setErrorMessages((prevState) => {
        return { ...prevState, [target.name]: error };
      });

      setCourseInput((prevState) => {
        if (target.type === 'checkbox' && target instanceof HTMLInputElement) {
          return {
            ...prevState,
            [target.name]: target.checked ? MEMBERSHIP_TYPES.PAID : MEMBERSHIP_TYPES.FREE,
          };
        } else if (['text', 'number', 'email'].includes(target.type)) {
          return { ...prevState, [target.name]: target.value };
        } else {
          return { ...prevState, [target.name]: target.value };
        }
      });
    }
  };

  // On submission callback
  const doSubmission = async (errors: Record<string, string> | null): Promise<void> => {
    // Checks for the null
    if (errors !== null) {
      // Set error message
      setErrorMessages(errors as any);
    } else {
      // By default membership type is FREE
      if (typeof courseInput.membershipType !== 'string') {
        courseInput.membershipType = MEMBERSHIP_TYPES.FREE;
      }

      // Search data map
      const searchData = {
        name: courseInput.name,
        instructor: courseInput.instructor,
        supplier: courseInput.supplier,
      };

      // Fetched existing matched course
      // Reference to the Firestore document of the course
      const matchedCourse = (await getMatchedDocument<typeof searchData>(
        searchData,
        coursesCollection,
      )) as QueryDocumentSnapshot<DocumentData>;

      // Get matched course data
      const existingCourse = matchedCourse?.data();

      // Save data into database
      // If matched course not found, then add course
      if (existingCourse === null || existingCourse === undefined) {
        await addDoc(coursesCollection, {
          ...courseInput,
          createdBy: user?.name,
          createdTime: new Date(),
          points: 0,
          count: 0,
          rateAvg: 0,
          lastReviewDate: '',
          lastReviewName: '',
        });

        // Display notification message
        toastNotification('Course has been added successfully.', NOTIFICATIONS.Success);

        // Reset local state
        setCourseInput({});

        setCreateCourseModal(false);
      } else {
        // Set error message
        setErrorMessages({ name: 'This course has already been added!' });
      }
    }
  };

  // Show form handler
  const handleShowForm = (): void => {
    setCreateCourseModal(!showCreateCourseModal);
    setErrorMessages({} as any);
  };

  const getInputElement = (field: IFieldsForInput): React.JSX.Element => (
    <div key={field.name}>
      <div>
        <label className="label">{field.label}</label>
      </div>
      <div>
        {field && field?.name === 'description' ? (
          <textarea
            className="create-course-input"
            rows={5}
            cols={33}
            placeholder={field.label}
            name={field.name}
            onChange={(e) => {
              handleChange(e, courseSchema, doChange);
            }}
          ></textarea>
        ) : (
          <input
            className="create-course-input"
            onChange={(e) => {
              handleChange(e, courseSchema, doChange);
            }}
            type={field.type}
            name={field.name}
            placeholder={field.placeholder}
          ></input>
        )}
        {typeof field.description === 'string' && (
          <p className="description">{field.description}</p>
        )}
        <p className="error">{errorMessages[field.name as keyof ICourse]}</p>
      </div>
    </div>
  );

  const getCheckboxElement = (field: IFieldsForInput): React.JSX.Element => (
    <div key={field.name}>
      <label className="label-checkbox">{field.label}</label>
      <input
        className="create-course-checkbox"
        onChange={(e) => {
          handleChange(e, courseSchema, doChange);
        }}
        type={field.type}
        name={field.name}
        placeholder={field.placeholder}
      ></input>
      {field.checkOptionLabel && <span>&nbsp;&nbsp;{field.checkOptionLabel}</span>}
      {typeof field.description === 'string' && <p className="description">{field.description}</p>}
      <p className="error">{errorMessages[field.name as keyof ICourse]}</p>
    </div>
  );

  const getDropdownElement = (field: IFieldsForInput): React.JSX.Element => (
    <div key={field.name}>
      <label className="label-dropdown">{field.label}</label>
      <div className="dropdown-element">
        <input
          className="create-course-input"
          onChange={(e) => {
            handleChange(e, courseSchema, doChange);
          }}
          type="number"
          name="courseLength"
          placeholder="Length"
          style={{ marginRight: '1rem' }}
        ></input>
        <select
          className="create-course-input"
          name="courseUnit"
          value={field.value}
          onChange={(e) => {
            handleChange(e, courseSchema, doChange);
          }}
        >
          <option key="init" value="">
            Select Period
          </option>
          {field.options?.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
      {typeof field.description === 'string' && <p className="description">{field.description}</p>}
      <p className="error">{errorMessages[field.name as keyof ICourse]}</p>
    </div>
  );

  if (!showCreateCourseModal) {
    return null as unknown as React.JSX.Element;
  }

  return (
    <Modal isOpen={showCreateCourseModal} size={MODAL_SIZE.LARGE} onClose={handleShowForm}>
      <h1 className="create-course-heading">Add New Course</h1>
      <form
        id="create-course-form"
        onSubmit={async (e) => {
          await handleSubmission<Partial<ICourse>>(e, courseSchema, courseInput, doSubmission);
        }}
        className="create-course"
      >
        {inputFields.map((field) => {
          if (['text', 'email', 'number'].includes(field.type)) {
            return getInputElement(field);
          } else if (field.type === 'dropdown') {
            return getDropdownElement(field);
          } else if (field.type === 'checkbox') {
            return getCheckboxElement(field);
          }
          return null;
        })}
        <div className="footer">
          <button className="button-action" type="submit">
            Add Course
          </button>
          <button onClick={handleShowForm} className="button-action">
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default CreateCourse;
