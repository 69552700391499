import Joi from 'joi';

import { COURSE_LENGTH } from '../data-structure/Enums';

// Create course validation rules
export const getCreateCourseSchema = (): Joi.ObjectSchema<any> =>
  Joi.object({
    name: Joi.string().min(3).max(500).required().label('Course Name'),
    instructor: Joi.string().min(3).max(500).label('Instructor'),
    courseLength: Joi.number().integer().positive().required().label('Course Length'),
    courseUnit: Joi.string()
      .allow(COURSE_LENGTH.HOURS, COURSE_LENGTH.DAYS, COURSE_LENGTH.WEEKS, COURSE_LENGTH.MONTHS)
      .required()
      .label('Course Length'),
    link: Joi.string().uri().label('Course Link'),
    supplier: Joi.string().min(3).max(500).required().label('Supplier Name'),
    skillsRequired: Joi.string().min(3).max(500).required().label('Skills Required'),
    skillsLearned: Joi.string().min(3).max(500).required().label('Skills Learned'),
    description: Joi.string().min(5).max(500).required().label('Course Description'),
    membershipType: Joi.string().label('Membership Type'),
  });

// Create course validation rules
export const getEditCourseSchema = (): Joi.ObjectSchema<any> =>
  Joi.object({
    name: Joi.string().min(3).max(500).required().label('Course Name'),
    instructor: Joi.string().min(3).max(500).label('Instructor'),
    courseLength: Joi.number().integer().positive().required().label('Course Length'),
    courseUnit: Joi.string()
      .allow(COURSE_LENGTH.HOURS, COURSE_LENGTH.DAYS, COURSE_LENGTH.WEEKS, COURSE_LENGTH.MONTHS)
      .required()
      .label('Course Length'),
    link: Joi.string().uri().label('Course Link'),
    supplier: Joi.string().min(3).max(500).required().label('Supplier Name'),
    skillsRequired: Joi.string().min(3).max(500).required().label('Skills Required'),
    skillsLearned: Joi.string().min(3).max(500).required().label('Skills Learned'),
    description: Joi.string().min(3).max(500).required().label('Course Description'),
    membershipType: Joi.string().label('Membership Type'),
    points: Joi.number().integer().optional(),
    id: Joi.string().min(3).max(50).optional(),
    createdBy: Joi.string().min(3).max(100).optional(),
    count: Joi.number().integer().optional(),
    lastReviewName: Joi.string().min(0).max(50).optional(),
    rateAvg: Joi.number().integer().optional(),
    editing: Joi.boolean().optional(),
    createdTime: Joi.any(),
    lastReviewDate: Joi.any(),
  });

// Set password validation rules
export const getSetPasswordSchema = (): Joi.ObjectSchema<any> =>
  Joi.object({
    password: Joi.string()
      .pattern(/^[a-zA-Z0-9]{6,30}$/)
      .required()
      .label('Password'),
    // TODO: have to implement password and repeat_password match rule
    // repeat_password: Joi.string().valid(Joi.ref('password')).required(),
    repeat_password: Joi.string().required().label('Repeat Password'),
  });

// Custom login validation rules
export const getCustomLoginSchema = (): Joi.ObjectSchema<any> =>
  Joi.object({
    email: Joi.string()
      .email({
        minDomainSegments: 2,
        maxDomainSegments: 2,
        tlds: { allow: ['se'] },
      })
      .required()
      .label('Email'),
    password: Joi.string()
      .pattern(/^[a-zA-Z0-9]{6,30}$/)
      .required()
      .label('Password'),
  });

// Set user role validation rules
export const getSetRoleSchema = (): Joi.ObjectSchema<any> =>
  Joi.object({
    userRole: Joi.string().required().label('User Role'),
  });

// Course review validation rules
export const getCourseReviewSchema = (): Joi.ObjectSchema<any> =>
  Joi.object({
    heading: Joi.string().required().label('Heading'),
    rate: Joi.number().min(1).max(5).integer().positive().required().label('Rating'),
    comment: Joi.string().required().label('Comment'),
  });
