import React, { useContext, useMemo } from 'react';
import { updateDoc, doc } from 'firebase/firestore';

import Form from '../../Form/Form';
import { Modal } from '../../Modals';
import { db } from '../../../firebase-config';
import { toastNotification } from '../../../utils/Toast';
import { isOwner, havePermission } from '../../../access/Permission';
import { getEditCourseSchema } from '../../../validation/Rules';
import { UserContext } from '../../../contexts/UserContext';
import { CourseContext } from '../../../contexts/CourseContext';
import { type ICourse } from '../../../data-structure/Interfaces';
import {
  MEMBERSHIP_TYPES,
  ACCESS_ITEMS,
  COLLECTIONS,
  MODAL_SIZE,
  NOTIFICATIONS,
} from '../../../data-structure/Enums';

import './Edit.scss';

const EditCourse: React.FC = (): React.JSX.Element | null => {
  // Get user context data
  const { user } = useContext(UserContext);

  // Get access permission
  const canEditOwn = havePermission(ACCESS_ITEMS.EditOwnCourse, user?.userRole);
  const canEditOthers = havePermission(ACCESS_ITEMS.EditOthersCourse, user?.userRole);

  // Get course context data
  const { courses, editingCourseId, setEditingCourseId } = useContext(CourseContext) ?? {};

  const course = useMemo(
    () => courses?.find((c: { id: any }) => c.id === editingCourseId),
    [courses, editingCourseId],
  );

  // Return if no course edit permission
  if (course) {
    const courseOwner = isOwner(user, course);
    if ((courseOwner && !canEditOwn) || (!courseOwner && !canEditOthers)) return null;
  }

  const updateDocument = async (courseInput: Partial<ICourse>): Promise<void> => {
    if (typeof courseInput.membershipType !== 'string') {
      courseInput.membershipType = MEMBERSHIP_TYPES.FREE;
    }

    // update data in database
    if (course && course != null) {
      await updateDoc(doc(db, COLLECTIONS.COURSES, course.id), courseInput);

      // Display notification message
      toastNotification('Course has been updated successfully.', NOTIFICATIONS.Success);
    }

    // set edit in course to false
    setEditingCourseId?.(undefined);
  };

  // Modal will be opened on click course edit button
  // Closes confirm modal window
  // Reset error, reset selected course, reset modal
  const handleCloseModal = (): null => {
    setEditingCourseId?.(undefined);
    return null;
  };

  return (
    <div>
      {course ? (
        <Modal isOpen={true} size={MODAL_SIZE.LARGE} onClose={handleCloseModal}>
          <h1 className="create-course-heading">Edit Course</h1>
          <Form
            key={course.id}
            onSubmitAction={updateDocument}
            onCancelAction={() => {
              setEditingCourseId?.(undefined);
            }}
            primaryButtonAction={'Update Course'}
            secondaryButtonAction={'Cancel'}
            getCourseSchema={getEditCourseSchema()}
            initialInput={course}
          />
        </Modal>
      ) : null}
    </div>
  );
};

export default EditCourse;
