import React, { useState, useEffect, useContext } from 'react';
import {
  collection,
  type DocumentData,
  type CollectionReference,
  type QueryDocumentSnapshot,
} from 'firebase/firestore';

import { db } from '../../../firebase-config';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { getMatchedDocuments } from '../../../firestoredb/Queries';
import { COLLECTIONS } from '../../../data-structure/Enums';

import './Statistics.scss';

const Statistics: React.FC<{
  courseId: string;
}> = ({ courseId }): React.JSX.Element | null => {
  // Get global context data
  const { reviewAdded } = useContext(GlobalContext);

  // Set local states
  const [ratings, setRatings] = useState<Map<number, number>>();

  // Define ratings count mapper
  const ratingCount = new Map<number, number>();

  // Get course evealuation collection reference
  const courseEvaluationCollection: CollectionReference<DocumentData> = collection(
    db,
    COLLECTIONS.COURSE_EVALUATION,
  );

  useEffect(() => {
    void getRatingsCount(courseId);
  }, [reviewAdded]);

  // Evaluate ratings statistics per rating
  const getRatingsCount = async (courseId: string): Promise<void> => {
    // Search data map
    const searchData = {
      courseId,
    };

    // Fetch matched course reviews
    const matchedData = (await getMatchedDocuments<typeof searchData>(
      searchData,
      courseEvaluationCollection,
    )) as Array<QueryDocumentSnapshot<DocumentData>>;

    // Evaluate base
    const base = matchedData?.filter((doc) => doc.data().rate > 0).length ?? 0;

    // Formulate ratings statistics
    [...Array(5).keys()].map((index) =>
      ratingCount.set(
        index + 1,
        (matchedData?.filter((doc) => doc.data().rate === index + 1).length * 100) / base || 0,
      ),
    );

    // Set statistics
    setRatings(ratingCount);
  };

  return (
    <div className="statistics">
      {ratings &&
        [...(ratings?.keys() || [])].reverse().map((rating) => (
          <div key={rating}>
            <div>{rating} star</div>
            <div>
              <div style={{ width: `${ratings.get(rating)}%` }}></div>
            </div>
            <div>{ratings.get(rating)}%</div>
          </div>
        ))}
    </div>
  );
};

export default Statistics;
