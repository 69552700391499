import React, { useState, useEffect } from 'react';

import Information from '../Information';
import { Modal } from '../../../components/Modals';
import { Rate, Reviews, Statistics } from '../../../components/Ratings';
import type { ICourse } from '../../../data-structure/Interfaces';
import { MODAL_SIZE } from '../../../data-structure/Enums';

import './Details.scss';

const Details: React.FC<{
  course: ICourse;
  setDetailsCourse: React.Dispatch<React.SetStateAction<ICourse | null>>;
}> = ({ course, setDetailsCourse }): React.JSX.Element => {
  // Define modal state, default set as open
  const [modalOpen, setModalOpen] = useState<boolean>(true);

  useEffect(() => {
    setModalOpen(true);
  }, []);

  // Closes course details modal window
  const handleCloseModal = (): null => {
    setModalOpen(false);
    setDetailsCourse(null);
    return null;
  };

  return (
    <Modal isOpen={modalOpen} size={MODAL_SIZE.LARGE} onClose={handleCloseModal}>
      <div className="course-details">
        <div>
          <Information course={course} ratings={false} />
        </div>
        <div>
          <div>
            <Reviews courseId={course.id} />
          </div>
          <div>
            <Rate course={course} updateRate={() => {}} />
            <Statistics courseId={course.id} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Details;
