import { getUserAccessMapper } from './AccessMapper';
import type { IAuthUser, ICourse } from '../data-structure/Interfaces';
import type { AccessItemTypes } from '../data-structure/Types';
import type { USER_ROLE } from '../data-structure/Enums';

/**
 * Checks whether a user role has permission to
 * access a specific feature or perform a certain action.
 *
 * @param {AccessItemTypes} item - The feature or action to check for permission.
 * @param {USER_ROLE | null} role - The user role to check permission for. If null, it checks user context..
 *
 * @returns {boolean} Returns true if the user role has permission, or false if not.
 */
export function havePermission(item: AccessItemTypes, role: USER_ROLE | null = null): boolean {
  // If item or role is not assigned, return false
  if (!item || !role) return false;

  // Get user access mapper matrix
  const accessMapper = getUserAccessMapper(item);

  // Evaluate permission
  return accessMapper[role];
}

/**
 * Checks whether a given subject (user) is the owner of a specific object (user or course or rating).
 *
 * @param {IAuthUser | null} subject - The user to be checked as the owner.
 * @param {Partial<IAuthUser | ICourse> | any} object - The object to be checked for ownership.
 *
 * @returns {boolean} Returns true if the subject is the owner of the object, or false if not.
 */
export function isOwner(
  subject: IAuthUser | null,
  object: Partial<IAuthUser | ICourse> | any,
): boolean {
  if (subject?.name === object.createdBy) {
    // For course
    return true;
  } else if (subject?.name === object.name) {
    // For user
    return true;
  } else if (subject?.name === object.username) {
    // For rating
    return true;
  }

  return false;
}
