import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { GlobalContext } from '../../../contexts/GlobalContext';

import './FilterButton.scss';

const SortBy: React.FC = (): React.JSX.Element => {
  const { showFilterByModal, setFilterByModalVisible } = useContext(GlobalContext);

  return (
    <button
      onClick={() => {
        setFilterByModalVisible(!showFilterByModal);
      }}
      className="filter-button"
    >
      <Link className="link-style" to="/courses">
        Filter by
      </Link>
    </button>
  );
};

export default SortBy;
