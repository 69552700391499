// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from '@firebase/firestore';

import { ENVIRONMENT } from './data-structure/Enums';
import config from './config.json';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
let firebaseConfig;
if (config.fsDbConfig === ENVIRONMENT.TEST) {
  firebaseConfig = {
    apiKey: process.env.REACT_APP_TEST_API_KEY,
    authDomain: process.env.REACT_APP_TEST_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_TEST_PROJECT_ID,
    storageBucket: process.env.REACT_APP_TEST_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_TEST_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_TEST_APP_ID,
    measurementId: process.env.REACT_APP_TEST_MEASUREMENT_ID,
  };
} else {
  firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  };
}

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);

// export default db;
