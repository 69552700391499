import bcrypt from 'bcryptjs';

/**
 * Generates a hashed password from a plain text password.
 *
 * @param plainPassword - The plain text password to be hashed.
 * @returns A Promise that resolves with the hashed password string.
 * @throws An error if there are issues with password hashing.
 */
export const hashedPassword = async (plainPassword: string): Promise<string> => {
  const saltRounds: number = 10;

  try {
    // Generate the hash asynchronously
    const salt = await bcrypt.genSalt(saltRounds);
    const hashedPassword = await bcrypt.hash(plainPassword, salt);
    return hashedPassword;
  } catch (error) {
    // Handle error
    console.error('Error hashing password:', error);
    throw error;
  }
};

/**
 * Verifies if a plain text password matches a hashed password.
 *
 * @param userPassword - The plain text password provided by the user.
 * @param hashedPassword - The stored hashed password for comparison.
 * @returns A Promise that resolves with a boolean value indicating if the passwords match.
 * @throws An error if there are issues with password verification.
 */
export const verifyPasswords = async (
  userPassword: string,
  hashedPassword: string,
): Promise<boolean> => {
  try {
    // Use bcrypt.compare() to compare the plain password with the hashed password
    const passwordMatched = await bcrypt.compare(userPassword, hashedPassword);
    return passwordMatched;
  } catch (error) {
    // Handle error
    console.error('Error comparing passwords:', error);
    throw error;
  }
};
