import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { Login, Logout, CustomLogin } from '../components/Auth';
import { SetPassword, UsersInfo } from '../components/Users';
import { Courses, CreateCourse } from '../components/Courses';
import NotFound from '../components/NotFound';
import ProtectedRoute from '../components/ProtectedRoute';
import { havePermission } from '../access/Permission';
import FilterModal from '../components/Filter/FilterWithTags/FilterModal';
import { type ICXRouter } from '../data-structure/Interfaces';
import { ACCESS_ITEMS } from '../data-structure/Enums';

const Router: React.FC<ICXRouter> = ({ user }): React.JSX.Element => {
  // Get access permissions
  const viewUsers = havePermission(ACCESS_ITEMS.ViewUsersPage, user?.userRole);
  const viewCourses = havePermission(ACCESS_ITEMS.ViewCoursesPage, user?.userRole);
  const viewSetPassword = havePermission(ACCESS_ITEMS.ViewSetPasswordPage, user?.userRole);

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/courses" replace />} />
      <Route path="/login" element={<Login />} />
      <Route path="/custom-login" element={<CustomLogin />} />
      <Route path="/logout" element={<ProtectedRoute user={user} />}>
        <Route path="/logout" element={<Logout />} />
      </Route>
      <Route path="/users" element={<ProtectedRoute user={user} canView={viewUsers} />}>
        <Route path="/users" element={<UsersInfo />} />
      </Route>
      <Route path="/courses" element={<ProtectedRoute user={user} canView={viewCourses} />}>
        <Route path="/courses" element={<Courses />} />
      </Route>
      <Route
        path="/set-password"
        element={<ProtectedRoute user={user} canView={viewSetPassword} />}
      >
        <Route path="/set-password" element={<SetPassword />} />
      </Route>
      <Route path="/create-course" element={<ProtectedRoute user={user} canView={viewCourses} />}>
        <Route path="/create-course" element={<CreateCourse />} />
      </Route>
      <Route path="/sort-by" element={<FilterModal />} />
      <Route path="/not-found" element={<NotFound />} />
    </Routes>
  );
};

export default Router;
