import React, { useContext, type ChangeEvent } from 'react';

import { SearchContext } from '../FilterBar';
import type { ISortByProps } from '../../../data-structure/Interfaces';

import './SortBy.scss';

const SortBy: React.FC<ISortByProps> = ({ sortOptions }): React.JSX.Element => {
  const sortSelected = useContext(SearchContext);

  const sortSelectHandler = (e: ChangeEvent<HTMLSelectElement>): void => {
    sortSelected.setSortType({
      ...sortSelected,
      value: e.target.value,
    });
  };

  return (
    <div className="sort-by-dropdown">
      <select onChange={sortSelectHandler} className="sort-by-select">
        {Object.values(sortOptions).map((option) => (
          <option key={option}>{option}</option>
        ))}
      </select>
    </div>
  );
};

export default SortBy;
