import React, { useState, useContext } from 'react';
import { doc, deleteDoc } from 'firebase/firestore';

import { ConfirmModal } from '../../Modals';
import { db } from '../../../firebase-config';
import { toastNotification } from '../../../utils/Toast';
import { havePermission } from '../../../access/Permission';
import { UserContext } from '../../../contexts/UserContext';
import { COLLECTIONS, ACCESS_ITEMS, NOTIFICATIONS } from '../../../data-structure/Enums';

import './Delete.scss';

const DeleteCourse: React.FC<{
  courseOwner: boolean;
  deleteCourse: string;
  setDeleteCourse: React.Dispatch<React.SetStateAction<string>>;
}> = ({ courseOwner, deleteCourse, setDeleteCourse }): React.JSX.Element | null => {
  // Get user context data
  const { user } = useContext(UserContext);

  // Get access permission
  const canDeleteOwn = havePermission(ACCESS_ITEMS.DeleteOwnCourse, user?.userRole);
  const canDeleteOthers = havePermission(ACCESS_ITEMS.DeleteOthersCourse, user?.userRole);

  // Return if no delete permission
  if ((courseOwner && !canDeleteOwn) || (!courseOwner && !canDeleteOthers)) return null;

  // Define modal state, default set as open
  const [modalOpen, setModalOpen] = useState<boolean>(true);

  const handleDelete = async (id: string): Promise<void> => {
    const courseDoc = doc(db, COLLECTIONS.COURSES, id);

    try {
      await deleteDoc(courseDoc);
      toastNotification('Course successfully deleted!', NOTIFICATIONS.Success);
      handleCloseModal();
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const handleCloseModal = (): null => {
    setDeleteCourse('');
    setModalOpen((prevState) => !prevState);
    return null;
  };

  return (
    <ConfirmModal
      title="Delete Course Review"
      description={`Do you really want to remove this course review?`}
      isOpen={modalOpen}
      onConfirm={async () => {
        await handleDelete(deleteCourse);
      }}
      onClose={handleCloseModal}
    />
  );
};

export default DeleteCourse;
