import React, { useContext, useEffect, useState } from 'react';
import { collection, onSnapshot } from 'firebase/firestore';

import Card from '../Card';
import { SearchContext } from '../../Filter/FilterBar';
import Pagination, { getPaginatedItems } from '../../Pagination';
import { db } from '../../../firebase-config';
import { havePermission } from '../../../access/Permission';
import { UserContext } from '../../../contexts/UserContext';
import { CourseContext } from '../../../contexts/CourseContext';
import { GlobalContext } from '../../../contexts/GlobalContext';
import type { ICourse } from '../../../data-structure/Interfaces';
import { ACCESS_ITEMS, COLLECTIONS, COURSE_SORT_OPTIONS } from '../../../data-structure/Enums';

import './List.scss';

const List: React.FC = (): React.JSX.Element | null => {
  // Get user context data
  const { user } = useContext(UserContext);
  const { tags } = useContext(GlobalContext);

  // Get access permission
  const viewPage = havePermission(ACCESS_ITEMS.ViewCoursesPage, user?.userRole);
  const viewList = havePermission(ACCESS_ITEMS.ViewCoursesList, user?.userRole);

  // Return if no courses list view permission
  if (!viewPage || !viewList) return null;

  // Get context data
  const searchInput = useContext(SearchContext);
  const courseContext = useContext(CourseContext);

  // Update Rate
  const [rateChange] = useState<number>(0);

  useEffect(
    () =>
      onSnapshot(collection(db, COLLECTIONS.COURSES), (snapshot) => {
        courseContext?.setCourses(
          snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })) as ICourse[],
        );
      }),
    [rateChange, searchInput.searchWord?.value, searchInput.sortType?.value],
  );

  const ourCourses = courseContext?.courses ?? [];

  let sortedCourse = [...ourCourses].sort((a, b) => (a.name > b.name ? 1 : -1));
  if (searchInput.sortType?.value === COURSE_SORT_OPTIONS.COURSE_SUPPLIER) {
    sortedCourse = [...ourCourses].sort((a, b) => (a.supplier > b.supplier ? 1 : -1));
  } else if (searchInput.sortType?.value === COURSE_SORT_OPTIONS.DESCENDING_COURSE_LENGTH) {
    sortedCourse = [...ourCourses].sort((a, b) => (a.courseLength > b.courseLength ? -1 : 1));
  } else if (searchInput.sortType?.value === COURSE_SORT_OPTIONS.ASCENDING_COURSE_LENGTH) {
    sortedCourse = [...ourCourses].sort((a, b) => (a.courseLength > b.courseLength ? 1 : -1));
  } else if (searchInput.sortType?.value === COURSE_SORT_OPTIONS.NAME_OF_COURSE) {
    sortedCourse = [...ourCourses].sort((a, b) => (a.name > b.name ? 1 : -1));
  } else if (searchInput.sortType?.value === COURSE_SORT_OPTIONS.REVIEW_DATE) {
    sortedCourse = [...ourCourses].sort((a, b) => (a.lastReviewDate > b.lastReviewDate ? -1 : 1));
  } else if (searchInput.sortType?.value === COURSE_SORT_OPTIONS.NUMBER_OF_STARS) {
    sortedCourse = [...ourCourses].sort((a, b) => (a.rateAvg > b.rateAvg ? -1 : 1));
  }

  const filteredCoursesByTags = sortedCourse.filter(
    (course) =>
      tags.filter(
        (tag) =>
          course.supplier.toLocaleLowerCase().includes(tag.toLocaleLowerCase()) ||
          course.skillsLearned.toLocaleLowerCase().includes(tag.toLocaleLowerCase()),
      ).length,
  );

  const filteredCourses = filteredCoursesByTags.length > 0 ? filteredCoursesByTags : sortedCourse;

  const searchCourse = filteredCourses.filter((x) => {
    const searchWordValue = searchInput.searchWord?.value;

    return typeof x.name === 'string'
      ? x.name
          .toLowerCase()
          .match(new RegExp(typeof searchWordValue === 'string' ? searchWordValue : '', 'gi'))
      : false || typeof x.skillsLearned === 'string'
        ? x.skillsLearned
            .toLowerCase()
            .match(new RegExp(typeof searchWordValue === 'string' ? searchWordValue : '', 'gi'))
        : false || typeof x.skillsRequired === 'string'
          ? x.skillsRequired
              .toLowerCase()
              .match(new RegExp(typeof searchWordValue === 'string' ? searchWordValue : '', 'gi'))
          : false || typeof x.description === 'string'
            ? x.description
                .toLowerCase()
                .match(new RegExp(typeof searchWordValue === 'string' ? searchWordValue : '', 'gi'))
            : false || typeof x.supplier === 'string'
              ? x.supplier
                  .toLowerCase()
                  .match(
                    new RegExp(typeof searchWordValue === 'string' ? searchWordValue : '', 'gi'),
                  )
              : false || typeof x.instructor === 'string'
                ? x.instructor
                    .toLowerCase()
                    .match(
                      new RegExp(typeof searchWordValue === 'string' ? searchWordValue : '', 'gi'),
                    )
                : false;
  });

  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const itemsPerPage: number = 3;
  const [itemOffset, setItemOffset] = useState<number>(0);

  // Gets paginated items
  const currentItems = getPaginatedItems(searchCourse, itemsPerPage, itemOffset);

  return (
    <>
      {currentItems.map((course) => {
        return <Card course={course} key={course.name} />;
      })}

      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={searchCourse.length}
        setItemOffset={setItemOffset}
      />
    </>
  );
};

export default List;
