import React, { useContext, useEffect, useState } from 'react';
import { collection, onSnapshot } from 'firebase/firestore';

import SetRole from '../SetRole';
import DeleteUser from '../Delete';
import { SearchContext } from '../../Filter/FilterBar';
import Pagination, { getPaginatedItems } from '../../Pagination';
import { db } from '../../../firebase-config';
import { isOwner, havePermission } from '../../../access/Permission';
import { UserContext } from '../../../contexts/UserContext';
import { type IAuthUser } from '../../../data-structure/Interfaces';
import { USER_SORT_OPTIONS, ACCESS_ITEMS, COLLECTIONS } from '../../../data-structure/Enums';

import './List.scss';

const List: React.FC = (): React.JSX.Element | null => {
  // Get user context data
  const { user: ctxUser, users, setUsers } = useContext(UserContext);

  // Get access permission
  const viewPage = havePermission(ACCESS_ITEMS.ViewUsersPage, ctxUser?.userRole);
  const viewList = havePermission(ACCESS_ITEMS.ViewUsersList, ctxUser?.userRole);

  // Return if no users list view permission
  if (!viewPage || !viewList) return null;

  // Set itemsPerPage and itemOffset
  const itemsPerPage: number = 4;
  const [itemOffset, setItemOffset] = useState<number>(0);

  // Define local state
  const [roleUser, setRoleUser] = useState<IAuthUser | null>(null);
  const [deleteUser, setDeleteUser] = useState<IAuthUser | null>(null);

  // Get search context data
  const searchInput = useContext(SearchContext);

  // Get access permissions
  const setOwnRole = havePermission(ACCESS_ITEMS.SetOwnRole, ctxUser?.userRole);
  const setOthersRole = havePermission(ACCESS_ITEMS.SetOthersRole, ctxUser?.userRole);
  const deleteOwn = havePermission(ACCESS_ITEMS.DeleteOwnAccount, ctxUser?.userRole);
  const deleteOthers = havePermission(ACCESS_ITEMS.DeleteOthersAccount, ctxUser?.userRole);
  const editOwn = havePermission(ACCESS_ITEMS.EditOwnAccount, ctxUser?.userRole);
  const editOthers = havePermission(ACCESS_ITEMS.EditOthersAccount, ctxUser?.userRole);

  useEffect(
    () =>
      onSnapshot(collection(db, COLLECTIONS.USERS), (snapshot) => {
        setUsers(snapshot.docs.map((doc) => ({ ...doc.data() })) as IAuthUser[]);
      }),
    [searchInput.searchWord?.value, searchInput.sortType?.value],
  );

  // Assign empty array if null
  const allUsers = users ?? [];

  // By default ascending user name
  let sortedUsers = [...allUsers].sort((a, b) => (a.name > b.name ? 1 : -1));
  if (searchInput.sortType?.value === USER_SORT_OPTIONS.DESCENDING_USER_NAME) {
    sortedUsers = [...allUsers].sort((a, b) => (a.name > b.name ? -1 : 1));
  } else if (searchInput.sortType?.value === USER_SORT_OPTIONS.ASCENDING_USER_EMAIL) {
    sortedUsers = [...allUsers].sort((a, b) => (a.email > b.email ? 1 : -1));
  } else if (searchInput.sortType?.value === USER_SORT_OPTIONS.DESCENDING_USER_EMAIL) {
    sortedUsers = [...allUsers].sort((a, b) => (a.email > b.email ? -1 : 1));
  } else if (searchInput.sortType?.value === USER_SORT_OPTIONS.ASCENDING_USER_LOCALE) {
    sortedUsers = [...allUsers].sort((a, b) => (a.locale > b.locale ? 1 : -1));
  } else if (searchInput.sortType?.value === USER_SORT_OPTIONS.DESCENDING_USER_LOCALE) {
    sortedUsers = [...allUsers].sort((a, b) => (a.locale > b.locale ? -1 : 1));
  }

  // Filter users according to search query
  const searchUsers = sortedUsers.filter((x) => {
    const searchWordValue = searchInput.searchWord?.value;

    return typeof x.name === 'string'
      ? x.name
          .toLowerCase()
          .match(new RegExp(typeof searchWordValue === 'string' ? searchWordValue : '', 'gi'))
      : false || typeof x.email === 'string'
        ? x.email
            .toLowerCase()
            .match(new RegExp(typeof searchWordValue === 'string' ? searchWordValue : '', 'gi'))
        : false || typeof x.locale === 'string'
          ? x.locale
              .toLowerCase()
              .match(new RegExp(typeof searchWordValue === 'string' ? searchWordValue : '', 'gi'))
          : false;
  });

  // Gets paginated items
  const currentItems = getPaginatedItems(searchUsers, itemsPerPage, itemOffset);

  return (
    <>
      {roleUser && <SetRole roleUser={roleUser} setRoleUser={setRoleUser} />}
      {deleteUser && <DeleteUser deleteUser={deleteUser} setDeleteUser={setDeleteUser} />}
      {currentItems.map((user) => {
        const userPicture =
          !user.deleted && user.picture
            ? user.picture
            : `${process.env.PUBLIC_URL}/images/deleted-user.jpg`;

        return (
          <div key={user.name} className="user-items">
            <div>
              <img src={userPicture} alt={user.name} />
            </div>
            <div>
              <div className="right-container">
                <div>
                  <h2>{user.name}</h2>
                </div>
                {!user?.deleted && (
                  <div className="button-box">
                    {((isOwner(ctxUser, user) && setOwnRole) ||
                      (!isOwner(ctxUser, user) && setOthersRole)) && (
                      <button
                        onClick={() => {
                          setRoleUser(user);
                        }}
                      >
                        Set Role
                      </button>
                    )}
                    {((isOwner(ctxUser, user) && editOwn) ||
                      (!isOwner(ctxUser, user) && editOthers)) && (
                      <button onClick={() => null}>Edit</button>
                    )}
                    {((isOwner(ctxUser, user) && deleteOwn) ||
                      (!isOwner(ctxUser, user) && deleteOthers)) && (
                      <button
                        onClick={() => {
                          setDeleteUser(user);
                        }}
                      >
                        Delete
                      </button>
                    )}
                  </div>
                )}
              </div>

              <div>
                <div>
                  <p>
                    <strong>Locale:</strong> {user.locale}
                  </p>
                  <p>
                    <strong>Email Address:</strong> {user.email}
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
      })}

      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={searchUsers.length}
        setItemOffset={setItemOffset}
      />
    </>
  );
};

export default List;
