import React, { useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FiMenu, FiX, FiPlus } from 'react-icons/fi';

import { havePermission } from '../../access/Permission';
import { GlobalContext } from '../../contexts/GlobalContext';
import type { IAuthUser } from '../../data-structure/Interfaces';
import { ACCESS_ITEMS } from '../../data-structure/Enums';

import './Navbar.scss';

const Navbar: React.FC<{
  user: IAuthUser | null;
}> = ({ user }): React.JSX.Element => {
  // Get location object
  const { pathname } = useLocation();

  // Get global context data
  const { showCreateCourseModal, setCreateCourseModal } = useContext(GlobalContext);

  // Set local state
  const [open, setOpen] = useState(false);

  // Get access permissions
  const usersPage = havePermission(ACCESS_ITEMS.ViewUsersPage, user?.userRole);
  const coursesPage = havePermission(ACCESS_ITEMS.ViewCoursesPage, user?.userRole);
  const createCourse = havePermission(ACCESS_ITEMS.CreateOwnCourse, user?.userRole);
  const setPasswordPage = havePermission(ACCESS_ITEMS.ViewSetPasswordPage, user?.userRole);

  const handleClick = (): void => {
    setOpen(!open);
  };

  return (
    <nav className="navbar">
      <Link to="/courses" className="nav-logo" style={{ display: 'flex', alignItems: 'center' }}>
        <img
          src={`${process.env.PUBLIC_URL}/images/logo-qlue.png`}
          alt="Qlue Logo"
          width="65"
          height="auto"
        />
        <p style={{ marginLeft: '8px' }}>Course Explorer</p>
      </Link>
      {user !== null && user !== undefined && (
        <>
          <div onClick={handleClick} className="nav-icon">
            {open ? <FiX /> : <FiMenu />}
          </div>
          <ul className={open ? 'nav-links active' : 'nav-links'}>
            {usersPage && (
              <li className="nav-item">
                <Link
                  className={pathname.includes('/users') ? 'nav-link active' : 'nav-link'}
                  to="/users"
                >
                  Users
                </Link>
              </li>
            )}
            {coursesPage && (
              <li className="nav-item">
                <Link
                  className={pathname.includes('/courses') ? 'nav-link active' : 'nav-link'}
                  to="/courses"
                >
                  Courses
                </Link>
              </li>
            )}
            {setPasswordPage && (
              <li className="nav-item">
                <Link
                  className={pathname.includes('/set-password') ? 'nav-link active' : 'nav-link'}
                  to="/set-password"
                >
                  Set Password
                </Link>
              </li>
            )}
            <li className="nav-item">
              <Link
                className={pathname.includes('/logout') ? 'nav-link active' : 'nav-link'}
                to="/logout"
              >
                Logout
              </Link>
            </li>
            {createCourse && (
              <li className="nav-item">
                <Link className="nav-link" to="/courses">
                  <button
                    className="button-add-new-course"
                    onClick={() => {
                      setCreateCourseModal(!showCreateCourseModal);
                    }}
                  >
                    Create New <FiPlus />
                  </button>
                </Link>
              </li>
            )}
          </ul>
        </>
      )}
    </nav>
  );
};

export default Navbar;
