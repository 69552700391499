import type {
  IOpenDatabase,
  IStoreData,
  IRetrieveData,
  IRemoveData,
} from '../data-structure/Interfaces';

/**
 * Opens an IndexedDB database with the specified name and object store name.
 *
 * @param {Object} params - The parameters object.
 * @param {string} params.databaseName - The name of the IndexedDB database to open.
 * @param {string} params.storeName - The name of the object store within the database.
 * @returns {Promise<IDBDatabase>} A Promise that resolves to the opened IDBDatabase.
 */
async function openDatabase({ databaseName, storeName }: IOpenDatabase): Promise<IDBDatabase> {
  // Open a new database
  const dbOpenRequest: IDBOpenDBRequest = indexedDB.open(databaseName, 1);

  // Promise to open a new database
  return await new Promise<IDBDatabase>((resolve, reject) => {
    dbOpenRequest.onupgradeneeded = (event: IDBVersionChangeEvent) => {
      const db: IDBDatabase = (event.target as IDBOpenDBRequest).result;

      // Create object store if it is not created
      if (!db.objectStoreNames.contains(storeName)) {
        db.createObjectStore(storeName, { keyPath: 'key' });
      }
    };

    // On successful database open
    dbOpenRequest.onsuccess = (event: Event) => {
      const db: IDBDatabase = (event.target as IDBOpenDBRequest).result;
      resolve(db);
    };

    // On database open failure
    dbOpenRequest.onerror = (event: Event) => {
      reject((event.target as IDBRequest).error);
    };
  });
}

/**
 * Stores data in an IndexedDB object store with the specified key.
 *
 * @param {Object} params - The parameters object.
 * @param {string} params.key - The key under which the data will be stored in the object store.
 * @param {T} params.data - The data to be stored in the object store.
 * @param {string} params.storeName - The name of the IndexedDB object store.
 * @param {string} params.databaseName - The name of the IndexedDB database.
 * @returns {Promise<void>} A Promise that resolves when the data has been successfully stored.
 *
 * @template T - The type of data to be stored.
 */
export async function storeData<T>({
  key,
  data,
  storeName,
  databaseName,
}: IStoreData<T>): Promise<void> {
  // Open database and get instance
  const db: IDBDatabase = await openDatabase({ databaseName, storeName });

  // Promise to store given data
  await new Promise<void>((resolve, reject) => {
    // Get database transaction
    const tx: IDBTransaction = db.transaction(storeName, 'readwrite');

    // Get object store
    const store: IDBObjectStore = tx.objectStore(storeName);

    // Store data
    store.put({ key, data });

    // Transaction complete event
    tx.oncomplete = () => {
      resolve();
    };

    // Transaction error event
    tx.onerror = (event: Event) => {
      reject((event.target as IDBRequest).error);
    };
  });
}

/**
 * Retrieves data from an IndexedDB object store with the specified key.
 *
 * @param {Object} params - The parameters object.
 * @param {string} params.key - The key under which the data will be retrieved from the object store.
 * @param {string} params.storeName - The name of the IndexedDB object store.
 * @param {string} params.databaseName - The name of the IndexedDB database.
 * @returns {Promise<string | undefined>} A Promise that resolves when the data has been successfully retrieved.
 */
export async function retrieveData({
  key,
  storeName,
  databaseName,
}: IRetrieveData): Promise<string | undefined> {
  // Open database and get instance
  const db: IDBDatabase = await openDatabase({ databaseName, storeName });

  // Promise to return requested data
  return await new Promise<string | undefined>((resolve, reject) => {
    // Get database transaction
    const tx: IDBTransaction = db.transaction(storeName, 'readonly');

    // Get object store
    const store: IDBObjectStore = tx.objectStore(storeName);

    // Get data request object
    const request: IDBRequest | undefined = store.get(key);

    // Request success event
    request.onsuccess = (event: Event) => {
      const result: any = (event.target as IDBRequest).result;
      resolve(result !== null && result !== undefined ? result.data : undefined);
    };

    // Request error event
    request.onerror = (event: Event) => {
      reject((event.target as IDBRequest).error);
    };
  });
}

/**
 * Remove data from an IndexedDB object store with the specified key.
 *
 * @param {Object} params - The parameters object.
 * @param {string} params.key - The key under which the data will be removed from the object store.
 * @param {string} params.storeName - The name of the IndexedDB object store.
 * @param {string} params.databaseName - The name of the IndexedDB database.
 * @returns {Promise<boolean>} A Promise that resolves when the data has been successfully removed.
 */
export async function removeData({ key, storeName, databaseName }: IRemoveData): Promise<boolean> {
  // Open database and get instance
  const db: IDBDatabase = await openDatabase({ databaseName, storeName });

  // Promise to remove stored data
  return await new Promise<boolean>((resolve, reject) => {
    // Get database transaction
    const tx: IDBTransaction = db.transaction(storeName, 'readwrite');

    // Get object store
    const store: IDBObjectStore = tx.objectStore(storeName);

    // Remove data request object
    const request: IDBRequest | undefined = store.delete(key);

    // Request success event
    request.onsuccess = () => {
      resolve(true);
    };

    // Request error event
    request.onerror = (event: Event) => {
      reject((event.target as IDBRequest).error);
    };
  });
}
