import React, { useEffect, useState } from 'react';
import {
  collection,
  type DocumentData,
  type CollectionReference,
  type QueryDocumentSnapshot,
} from 'firebase/firestore';

import Stars from '../Stars';
import { db } from '../../../firebase-config';
import { getDateString } from '../../../utils/Date';
import { getMatchedDocuments } from '../../../firestoredb/Queries';
import { COLLECTIONS } from '../../../data-structure/Enums';

import './Reviews.scss';

const Reviews: React.FC<{
  courseId: string;
}> = ({ courseId }): React.JSX.Element => {
  // Set local states
  const [reviews, setReviews] = useState<DocumentData[]>([]);

  // Get course evealuation collection reference
  const courseEvaluationCollection: CollectionReference<DocumentData> = collection(
    db,
    COLLECTIONS.COURSE_EVALUATION,
  );

  useEffect(() => {
    void getReviews(courseId);
  }, []);

  // Evaluate ratings statistics per rating
  const getReviews = async (courseId: string): Promise<void> => {
    // Search data map
    const searchData = {
      courseId,
    };

    // Fetch matched course reviews
    const matchedData = (await getMatchedDocuments<typeof searchData>(
      searchData,
      courseEvaluationCollection,
    )) as Array<QueryDocumentSnapshot<DocumentData>>;

    // Set statistics
    setReviews(
      (matchedData ?? []).map((doc) => {
        return doc.data();
      }),
    );
  };

  return (
    <div className="course-review">
      <div>
        <h2>Reviews</h2>
      </div>
      <div>
        {reviews.map((review) => (
          <div key={review.username}>
            <p>{review.heading}</p>
            <div>
              <Stars rating={review.rate} />
            </div>
            <p>{review.comment}</p>
            <p>
              <strong>
                {review.username}, {getDateString(review.date.seconds)}
              </strong>
            </p>
          </div>
        ))}
        {!reviews.length && <p>No body reviewed this course yet! Be the first one to review it!</p>}
      </div>
    </div>
  );
};

export default Reviews;
