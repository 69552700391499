import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  collection,
  type DocumentData,
  type CollectionReference,
  type QueryDocumentSnapshot,
} from 'firebase/firestore';

import { db } from '../../../firebase-config';
import { storeJwtToken } from '../../../encryption/Jwt';
import { UserContext } from '../../../contexts/UserContext';
import { havePermission } from '../../../access/Permission';
import { getCustomLoginSchema } from '../../../validation/Rules';
import { handleChange, handleSubmission } from '../../../form/Handlers';
import { setCustomLoginInputFieldMapper } from '../../../form/InputFieldMappers';
import { verifyPasswords } from '../../../encryption/Hash';
import { getMatchedDocument } from '../../../firestoredb/Queries';
import type { IAuthUser, ICustomLogin } from '../../../data-structure/Interfaces';
import type { EventTypes, TargetElementTypes } from '../../../data-structure/Types';
import { COLLECTIONS, ACCESS_ITEMS } from '../../../data-structure/Enums';

import './CustomLogin.scss';

const CustomLogin: React.FC = (): React.JSX.Element => {
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);

  // define local states
  const [errorMessages, setErrorMessages] = useState<Partial<ICustomLogin>>({});
  const [customLoginInput, setCustomLoginInput] = useState<Partial<ICustomLogin>>({});

  // Get user collection reference
  const usersCollection: CollectionReference<DocumentData> = collection(db, COLLECTIONS.USERS);

  useEffect(() => {
    if (user !== null && user !== undefined) {
      navigate('/courses');
    }
  }, [user, navigate]);

  // gets input field mapper
  const inputFields = setCustomLoginInputFieldMapper();

  // on change callback
  const doChange = (e: EventTypes, error: string | null): void => {
    if ('target' in e) {
      const target = e.target as TargetElementTypes;

      setErrorMessages((prevState) => {
        return { ...prevState, [target.name]: error };
      });

      setCustomLoginInput((prevState) => {
        return { ...prevState, [target.name]: target.value.trim() };
      });
    }
  };

  // on submission callback
  const doSubmission = async (errors: Record<string, string> | null): Promise<void> => {
    // checks for the null
    if (errors !== null) {
      // set error message
      setErrorMessages(errors as any);
    } else {
      // Search data map
      const searchData = {
        email: customLoginInput.email,
      };

      // Fetched existing matched user
      // Reference to the Firestore document of the user you want to update
      const matchedUser = (await getMatchedDocument<typeof searchData>(
        searchData,
        usersCollection,
      )) as QueryDocumentSnapshot<DocumentData>;

      // Get matched user data
      const userData = matchedUser?.data();

      // Check access permission
      if (!havePermission(ACCESS_ITEMS.UseCustomLogin, userData?.userRole)) {
        // Set error message
        setErrorMessages({ email: 'Email and/or password did not match!' });
        return;
      }

      try {
        // Verify user password and hashed password
        let passwordMatched: boolean = false;
        if (customLoginInput?.password !== undefined && userData?.password !== undefined) {
          passwordMatched = await verifyPasswords(customLoginInput.password, userData?.password);
        }

        // If password matches, set user context and jwt token
        if (passwordMatched) {
          // Delete sensitive data
          delete userData?.password;

          // Sets user information in user context
          setUser(userData as IAuthUser);

          // Update user jwt token
          await storeJwtToken(userData);

          // reset local state
          setCustomLoginInput({});

          // reset form
          setErrorMessages({});
        } else {
          // Set error message
          setErrorMessages({ email: 'Email and/or password did not match!' });
        }
      } catch (error) {
        console.error('Error:', error);
        throw error;
      }
    }
  };

  const handleShowForm = (): void => {
    setErrorMessages({});
    navigate(-1);
  };

  return (
    <div>
      <form
        id="custom-login-form"
        onSubmit={async (e) => {
          await handleSubmission<Partial<ICustomLogin>>(
            e,
            getCustomLoginSchema(),
            customLoginInput,
            doSubmission,
          );
        }}
        className="custom-login"
      >
        <h2 style={{ margin: '0 auto' }}>Custom user login</h2>
        {inputFields.map((field) => (
          <div key={field.name}>
            <div>
              <div>
                <label className="label">{field.label}</label>
              </div>
              <div>
                <input
                  className="custom-login-input"
                  onChange={(e) => {
                    handleChange(e, getCustomLoginSchema(), doChange);
                  }}
                  type={field.type}
                  name={field.name}
                  placeholder={field.placeholder}
                ></input>
                {typeof field.description === 'string' && (
                  <p className="description">{field.description}</p>
                )}
                <p className="error">{errorMessages[field.name as keyof ICustomLogin]}</p>
              </div>
            </div>
            <div style={{ clear: 'both' }}></div>
          </div>
        ))}

        <div className="buttons">
          <button className="button-action" type="submit">
            Signin
          </button>
          <button onClick={handleShowForm} className="button-action">
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default CustomLogin;
