import React, { useState } from 'react';

import './Stars.scss';

const Stars: React.FC<{
  rating?: number;
  setRating?: React.Dispatch<React.SetStateAction<number>>;
}> = ({ rating, setRating }): React.JSX.Element | null => {
  // Define local states
  const [hover, setHover] = useState(0);

  return (
    <div className="starrating">
      {[...Array(5)].map((_, index) => {
        index += 1;

        return (
          <button
            type="button"
            key={index}
            onClick={() => {
              setRating?.(index);
            }}
            onMouseEnter={() => {
              setHover(index);
            }}
            onMouseLeave={() => {
              setHover(rating ?? 0);
            }}
          >
            <span>
              <svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M15.3518 0.847604L18.5099 7.29982C18.7295 7.74857 19.1541 8.05952 19.6453 8.13137L26.7072 9.16611C27.9443 9.34749 28.4378 10.8799 27.543 11.7591L22.433 16.7814C22.0779 17.1307 21.9156 17.6342 21.9997 18.1271L23.2058 25.2189C23.4172 26.4609 22.1241 27.4079 21.0178 26.8219L14.7018 23.4739C14.2625 23.2413 13.7375 23.2413 13.2982 23.4739L6.98215 26.8219C5.87593 27.4085 4.58281 26.4609 4.79424 25.2189L6.00034 18.1271C6.08444 17.6342 5.92207 17.1307 5.56696 16.7814L0.456968 11.7591C-0.437821 10.8793 0.0557144 9.3469 1.29277 9.16611L8.35471 8.13137C8.84591 8.05952 9.27053 7.74857 9.49014 7.29982L12.6482 0.847604C13.2007 -0.282535 14.7987 -0.282535 15.3518 0.847604Z"
                  fill={index <= (hover !== 0 ? hover : rating ?? 0) ? '#FFD449' : '#F2F2F2'}
                />
              </svg>
            </span>
          </button>
        );
      })}
    </div>
  );
};

export default Stars;
