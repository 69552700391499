import React, { useContext, useState, useEffect } from 'react';
import {
  doc,
  addDoc,
  updateDoc,
  collection,
  type DocumentData,
  type CollectionReference,
} from 'firebase/firestore';

import Stars from '../Stars';
import { Modal } from '../../Modals';
import { db } from '../../../firebase-config';
import Information from '../../Courses/Information';
import { isOwner, havePermission } from '../../../access/Permission';
import { UserContext } from '../../../contexts/UserContext';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { getCourseReviewSchema } from '../../../validation/Rules';
import { handleChange, handleSubmission } from '../../../form/Handlers';
import { courseReviewFieldMapper } from '../../../form/InputFieldMappers';
import { toastNotification } from '../../../utils/Toast';
import type {
  ICourseReview,
  IAddCourseReviewProps,
  IFieldsForInput,
} from '../../../data-structure/Interfaces';
import type { EventTypes, TargetElementTypes } from '../../../data-structure/Types';
import {
  COLLECTIONS,
  ACCESS_ITEMS,
  MODAL_SIZE,
  NOTIFICATIONS,
} from '../../../data-structure/Enums';

import './AddRating.scss';

const AddRating: React.FC<IAddCourseReviewProps> = ({
  updateRate,
  initialRating,
  reviewCourse,
  setReviewCourse,
  disabledRating,
}): React.JSX.Element | null => {
  // Get user context data
  const { user } = useContext(UserContext);
  const { setReviewAdded } = useContext(GlobalContext);

  // Get access permissions
  const canRateOwnCourse = havePermission(ACCESS_ITEMS.RateOwnCourse, user?.userRole);
  const canRateOthersCourse = havePermission(ACCESS_ITEMS.RateOthersCourse, user?.userRole);

  // Return if no rating permission
  const courseOwner = isOwner(user, reviewCourse);
  if ((courseOwner && !canRateOwnCourse) || (!courseOwner && !canRateOthersCourse)) {
    // return null;
  }

  // Define modal state, default set as open
  const [modalOpen, setModalOpen] = useState<boolean>(true);

  // Define local states
  const [rating, setRating] = useState<number>(initialRating);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [reviewInput, setReviewInput] = useState<Partial<ICourseReview>>({});
  const [errorMessages, setErrorMessages] = useState<Partial<ICourseReview>>({});

  // Get course review schema and input field mapper
  const inputFields = courseReviewFieldMapper();
  const courseReviewSchema = getCourseReviewSchema();

  // Get course evaluation collection reference
  const courseEvaluationCollection: CollectionReference<DocumentData> = collection(
    db,
    COLLECTIONS.COURSE_EVALUATION,
  );

  useEffect(() => {
    setReviewInput((prevState) => {
      return { ...prevState, rate: rating };
    });
  }, [rating]);

  // On change callback
  const doChange = (e: EventTypes, error: string | null): void => {
    if ('target' in e) {
      const target = e.target as TargetElementTypes;

      setErrorMessages((prevState) => {
        return { ...prevState, [target.name]: error };
      });

      setReviewInput((prevState) => {
        return { ...prevState, [target.name]: target.value.trim() };
      });
    }
  };

  // on submission callback
  const doSubmission = async (errors: Record<string, string> | null): Promise<void> => {
    // checks for the null
    if (errors !== null) {
      // set error message
      setErrorMessages(errors as any);
    } else {
      try {
        // Add course review
        await addCourseReview();

        // Display notification message
        toastNotification('Course review has been added successfully.', NOTIFICATIONS.Success);

        // Set form submitted
        setReviewAdded(true);
        setFormSubmitted(true);
      } catch (error) {
        console.error('Error:', error);
        throw error;
      }
    }
  };

  const addCourseReview = async (): Promise<void> => {
    if (!disabledRating) {
      // Add new course review
      await addDoc(courseEvaluationCollection, {
        ...reviewInput,
        rate: parseInt((reviewInput.rate ?? '') as string),
        courseId: reviewCourse.id,
        username: user?.name,
        date: new Date(),
      });
    }

    // Update course
    await updateCourse();
  };

  const updateCourse = async (): Promise<void> => {
    // Get course document reference
    const courseDoc = doc(db, COLLECTIONS.COURSES, reviewCourse.id);

    // Update course
    await updateDoc(courseDoc, {
      points: reviewCourse.points + (reviewInput.rate as number),
      count: reviewCourse.count + 1,
      rateAvg: (reviewCourse.points + (reviewInput.rate as number)) / (reviewCourse.count + 1),
      lastReviewDate: new Date(),
      lastReviewName: user?.name,
    });

    // Update rating
    updateRate(1);
  };

  // Modal will be opened on click button
  // Closes course review modal window
  // Reset error, reset review course, reset modal
  const handleCloseModal = (): null => {
    setRating(0);
    setReviewAdded(false);
    setFormSubmitted(false);
    setReviewInput({});
    setErrorMessages({});
    setReviewCourse(null);
    setModalOpen((prevState) => !prevState);
    return null;
  };

  const getInputElement = (field: IFieldsForInput): React.JSX.Element => (
    <div key={field.name}>
      <div>
        <label>{field.label}</label>
      </div>
      <div>
        <input
          className="input-fields"
          onChange={(e) => {
            handleChange(e, courseReviewSchema, doChange);
          }}
          type={field.type}
          name={field.name}
          placeholder={field.placeholder}
        ></input>
        {typeof field.description === 'string' && (
          <p className="description">{field.description}</p>
        )}
        <p className="error">{errorMessages[field.name as keyof ICourseReview]}</p>
      </div>
    </div>
  );

  const getTextareaElement = (field: IFieldsForInput): React.JSX.Element => (
    <div key={field.name}>
      <div>
        <label>{field.label}</label>
      </div>
      <div>
        <textarea
          className="input-fields"
          rows={5}
          cols={33}
          placeholder={field.label}
          name={field.name}
          onChange={(e) => {
            handleChange(e, courseReviewSchema, doChange);
          }}
        ></textarea>
        {typeof field.description === 'string' && (
          <p className="description">{field.description}</p>
        )}
        <p className="error">{errorMessages[field.name as keyof ICourseReview]}</p>
      </div>
    </div>
  );

  const getRatingStarElement = (field: IFieldsForInput): React.JSX.Element => (
    <div key={field.name}>
      <div>
        <label>{field.label}</label>
      </div>
      <div>
        <Stars key={field.name} rating={rating} setRating={setRating} />
        {typeof field.description === 'string' && (
          <p className="description">{field.description}</p>
        )}
        <p className="error">{errorMessages[field.name as keyof ICourseReview]}</p>
      </div>
    </div>
  );

  return (
    <Modal isOpen={modalOpen} size={MODAL_SIZE.LARGE} onClose={handleCloseModal}>
      <div>
        <form
          id="add-review-form"
          onSubmit={async (e) => {
            await handleSubmission<Partial<ICourseReview>>(
              e,
              courseReviewSchema,
              reviewInput,
              doSubmission,
            );
          }}
        >
          {!formSubmitted && !disabledRating && <h2>Leave a review</h2>}

          {!formSubmitted &&
            !disabledRating &&
            inputFields.map((field) => {
              if (field.name === 'rate') {
                return getRatingStarElement(field);
              } else if (['text', 'email', 'number'].includes(field.type)) {
                return getInputElement(field);
              } else if (field.type === 'textarea') {
                return getTextareaElement(field);
              }
              return null;
            })}

          <div className="buttons">
            {!formSubmitted && !disabledRating && (
              <button className="button-action" type="submit">
                Submit Review
              </button>
            )}
            {(formSubmitted || disabledRating) && (
              <div>
                <h2>Thanks for your review!</h2>
                <button onClick={handleCloseModal} className="button-action">
                  Close
                </button>
              </div>
            )}
          </div>
        </form>
      </div>
      <div>
        <Information course={reviewCourse} statistics={true} />
      </div>
    </Modal>
  );
};

export default AddRating;
