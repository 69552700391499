import React, { useEffect, useContext } from 'react';
import { ToastContainer } from 'react-toastify';

import Router from './router';
import Navbar from './components/Navbar';
import { UserContext } from './contexts/UserContext';
import { getAuthenticatedUser } from './authentication/User';
import { type IAuthUser } from './data-structure/Interfaces';

import './App.scss';
import 'react-toastify/dist/ReactToastify.css';

function App(): React.JSX.Element {
  // Get user context data
  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    // Retrives logged in user data from jwt token
    // Also set the logged in user to user context
    const getAuthUser = async (): Promise<void> => {
      // Retrieves user data from jwt token
      const authenticatedUser = await getAuthenticatedUser();

      // Sets user data into user context
      setUser(authenticatedUser as IAuthUser);
    };

    // If context user is available, don't retrieve jwt token
    if (user === null || user === undefined) {
      void getAuthUser();
    }
  }, []);

  return (
    <div className="body">
      <ToastContainer />
      <Navbar user={user} />
      <Router user={user} />
    </div>
  );
}

export default App;
