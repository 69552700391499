import type { IUserPermissions } from '../data-structure/Interfaces';
import type { AccessMapTypes, AccessItemTypes } from '../data-structure/Types';
import { USER_ROLE, ACCESS_ITEMS } from '../data-structure/Enums';

// Define the access map to control access
// permissions based on user roles.
const accessMap: AccessMapTypes = {
  [ACCESS_ITEMS.UseGoogleLogin]: {
    [USER_ROLE.USER]: true,
    [USER_ROLE.ADMIN]: true,
    [USER_ROLE.SUPERADMIN]: true,
  },
  [ACCESS_ITEMS.UseCustomLogin]: {
    [USER_ROLE.USER]: false,
    [USER_ROLE.ADMIN]: true,
    [USER_ROLE.SUPERADMIN]: true,
  },
  [ACCESS_ITEMS.CanSetPassword]: {
    [USER_ROLE.USER]: false,
    [USER_ROLE.ADMIN]: true,
    [USER_ROLE.SUPERADMIN]: true,
  },
  [ACCESS_ITEMS.ViewOwnInfo]: {
    [USER_ROLE.USER]: true,
    [USER_ROLE.ADMIN]: true,
    [USER_ROLE.SUPERADMIN]: true,
  },
  [ACCESS_ITEMS.ViewOthersInfo]: {
    [USER_ROLE.USER]: false,
    [USER_ROLE.ADMIN]: true,
    [USER_ROLE.SUPERADMIN]: true,
  },
  [ACCESS_ITEMS.ViewUsersList]: {
    [USER_ROLE.USER]: false,
    [USER_ROLE.ADMIN]: true,
    [USER_ROLE.SUPERADMIN]: true,
  },
  [ACCESS_ITEMS.CanSearchUser]: {
    [USER_ROLE.USER]: false,
    [USER_ROLE.ADMIN]: true,
    [USER_ROLE.SUPERADMIN]: true,
  },
  [ACCESS_ITEMS.CreateOwnAccount]: {
    [USER_ROLE.USER]: true,
    [USER_ROLE.ADMIN]: true,
    [USER_ROLE.SUPERADMIN]: true,
  },
  [ACCESS_ITEMS.CreateOthersAccount]: {
    [USER_ROLE.USER]: false,
    [USER_ROLE.ADMIN]: false,
    [USER_ROLE.SUPERADMIN]: false,
  },
  [ACCESS_ITEMS.EditOwnAccount]: {
    [USER_ROLE.USER]: false,
    [USER_ROLE.ADMIN]: false,
    [USER_ROLE.SUPERADMIN]: false,
  },
  [ACCESS_ITEMS.EditOthersAccount]: {
    [USER_ROLE.USER]: false,
    [USER_ROLE.ADMIN]: false,
    [USER_ROLE.SUPERADMIN]: false,
  },
  [ACCESS_ITEMS.DeleteOwnAccount]: {
    [USER_ROLE.USER]: false,
    [USER_ROLE.ADMIN]: false,
    [USER_ROLE.SUPERADMIN]: false,
  },
  [ACCESS_ITEMS.DeleteOthersAccount]: {
    [USER_ROLE.USER]: false,
    [USER_ROLE.ADMIN]: false,
    [USER_ROLE.SUPERADMIN]: true,
  },
  [ACCESS_ITEMS.SetOwnRole]: {
    [USER_ROLE.USER]: false,
    [USER_ROLE.ADMIN]: false,
    [USER_ROLE.SUPERADMIN]: true,
  },
  [ACCESS_ITEMS.SetOthersRole]: {
    [USER_ROLE.USER]: false,
    [USER_ROLE.ADMIN]: false,
    [USER_ROLE.SUPERADMIN]: true,
  },
  [ACCESS_ITEMS.ViewOwnCourse]: {
    [USER_ROLE.USER]: true,
    [USER_ROLE.ADMIN]: true,
    [USER_ROLE.SUPERADMIN]: true,
  },
  [ACCESS_ITEMS.ViewOthersCourse]: {
    [USER_ROLE.USER]: true,
    [USER_ROLE.ADMIN]: true,
    [USER_ROLE.SUPERADMIN]: true,
  },
  [ACCESS_ITEMS.ViewCoursesList]: {
    [USER_ROLE.USER]: true,
    [USER_ROLE.ADMIN]: true,
    [USER_ROLE.SUPERADMIN]: true,
  },
  [ACCESS_ITEMS.CanSearchCourse]: {
    [USER_ROLE.USER]: true,
    [USER_ROLE.ADMIN]: true,
    [USER_ROLE.SUPERADMIN]: true,
  },
  [ACCESS_ITEMS.CreateOwnCourse]: {
    [USER_ROLE.USER]: true,
    [USER_ROLE.ADMIN]: true,
    [USER_ROLE.SUPERADMIN]: true,
  },
  [ACCESS_ITEMS.CreateOthersCourse]: {
    [USER_ROLE.USER]: false,
    [USER_ROLE.ADMIN]: false,
    [USER_ROLE.SUPERADMIN]: false,
  },
  [ACCESS_ITEMS.EditOwnCourse]: {
    [USER_ROLE.USER]: true,
    [USER_ROLE.ADMIN]: true,
    [USER_ROLE.SUPERADMIN]: true,
  },
  [ACCESS_ITEMS.EditOthersCourse]: {
    [USER_ROLE.USER]: false,
    [USER_ROLE.ADMIN]: true,
    [USER_ROLE.SUPERADMIN]: true,
  },
  [ACCESS_ITEMS.DeleteOwnCourse]: {
    [USER_ROLE.USER]: false,
    [USER_ROLE.ADMIN]: true,
    [USER_ROLE.SUPERADMIN]: true,
  },
  [ACCESS_ITEMS.DeleteOthersCourse]: {
    [USER_ROLE.USER]: false,
    [USER_ROLE.ADMIN]: false,
    [USER_ROLE.SUPERADMIN]: true,
  },
  [ACCESS_ITEMS.RateOwnCourse]: {
    [USER_ROLE.USER]: true,
    [USER_ROLE.ADMIN]: true,
    [USER_ROLE.SUPERADMIN]: true,
  },
  [ACCESS_ITEMS.RateOthersCourse]: {
    [USER_ROLE.USER]: true,
    [USER_ROLE.ADMIN]: true,
    [USER_ROLE.SUPERADMIN]: true,
  },
  [ACCESS_ITEMS.ViewUsersPage]: {
    [USER_ROLE.USER]: false,
    [USER_ROLE.ADMIN]: true,
    [USER_ROLE.SUPERADMIN]: true,
  },
  [ACCESS_ITEMS.ViewCoursesPage]: {
    [USER_ROLE.USER]: true,
    [USER_ROLE.ADMIN]: true,
    [USER_ROLE.SUPERADMIN]: true,
  },
  [ACCESS_ITEMS.ViewSetPasswordPage]: {
    [USER_ROLE.USER]: false,
    [USER_ROLE.ADMIN]: true,
    [USER_ROLE.SUPERADMIN]: true,
  },
  // Add more feature-specific access rules as needed.
};

/**
 * Retrieves an access map that defines which user roles can
 * access specific features or perform certain actions.
 *
 * @returns {AccessMapTypes} An access map specifying access permissions for user roles.
 */
export function getAccessMapper(): AccessMapTypes {
  // Return user access permission map.
  return accessMap;
}

/**
 * Retrieves user access mapper that defines which user roles can
 * access specific features or perform certain action.
 *
 * @param {AccessItemTypes} item - The feature or action to check for permission.
 * @returns {IUserPermissions} A user access mapper specifying access permissions for user roles.
 */
export function getUserAccessMapper(item: AccessItemTypes): IUserPermissions {
  // Get access mapper
  const accessMap = getAccessMapper();

  // Return user access permission mapper.
  return accessMap[item];
}
