import React, { createContext, useEffect, useState } from 'react';

import type { IGlobalState, IGlobalContext } from '../data-structure/Interfaces';

const initialState: IGlobalState = {
  showCreateCourseModal: false,
  setCreateCourseModal: () => {},
  showFilterByModal: false,
  setFilterByModalVisible: () => {},
  tags: JSON.parse(window.localStorage.getItem('tags') ?? '[]') as string[],
  setTags: () => [],
  reviewAdded: false,
  setReviewAdded: () => {},
};

export const GlobalContext = createContext<IGlobalContext>(initialState);

export const GlobalContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }): React.JSX.Element => {
  const [showCreateCourseModal, setCreateCourseModal] = useState(
    initialState.showCreateCourseModal,
  );

  const [reviewAdded, setReviewAdded] = useState(initialState.reviewAdded);
  const [showFilterByModal, setFilterByModalVisible] = useState(initialState.showFilterByModal);

  const [tags, setTags] = useState<string[]>(initialState.tags);
  useEffect(() => {
    window.localStorage.setItem('tags', JSON.stringify(tags));
  }, [tags]);

  return (
    <GlobalContext.Provider
      value={{
        showCreateCourseModal,
        setCreateCourseModal,
        showFilterByModal,
        setFilterByModalVisible,
        tags,
        setTags,
        reviewAdded,
        setReviewAdded,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
