import jwr_decode from 'jwt-decode';

import { retrieveData } from '../indexed-db/Connector';
import { INDEXED_DB_OPTIONS } from '../data-structure/Enums';

/**
 * Retrieves and decodes the JWT token stored in IndexedDB to get the authenticated user's information.
 *
 * @returns {Promise<unknown>} A Promise that resolves with the decoded user information or rejects with an error.
 * @throws {DOMException} If an error occurs while retrieving or decoding the JWT token.
 */
export async function getAuthenticatedUser(): Promise<unknown> {
  // Retrieves logged in user data from jwt token
  return await retrieveData({
    key: INDEXED_DB_OPTIONS.JWT_KEY_NAME,
    storeName: INDEXED_DB_OPTIONS.JWT_STORE_NAME,
    databaseName: INDEXED_DB_OPTIONS.DATABASE_NAME,
  })
    .then((token: string | undefined) => {
      if (token !== undefined) {
        // Decode jwt token
        const decodedToken = jwr_decode(token);

        // Return decoded token
        return decodedToken;
      } else {
        return undefined;
      }
    })
    .catch((error: DOMException) => {
      throw error;
    });
}

/**
 * Checks if a user is authenticated based on the presence of a decoded JWT token.
 *
 * @returns {Promise<boolean>} A Promise that resolves with `true` if the user is authenticated, `false` otherwise.
 */
export async function isAuthenticatedUser(): Promise<boolean> {
  const user: unknown = await getAuthenticatedUser();

  if (user !== null && user !== undefined) {
    return true;
  }

  return false;
}
