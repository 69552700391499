import React, { useContext, useState } from 'react';
import {
  updateDoc,
  collection,
  type DocumentData,
  type CollectionReference,
  type QueryDocumentSnapshot,
} from 'firebase/firestore';

import { Modal } from '../../Modals';
import { db } from '../../../firebase-config';
import { storeJwtToken } from '../../../encryption/Jwt';
import { isOwner, havePermission } from '../../../access/Permission';
import { UserContext } from '../../../contexts/UserContext';
import { getSetRoleSchema } from '../../../validation/Rules';
import { handleChange, handleSubmission } from '../../../form/Handlers';
import { setRoleInputFieldMapper } from '../../../form/InputFieldMappers';
import { getMatchedDocument } from '../../../firestoredb/Queries';
import { toastNotification } from '../../../utils/Toast';
import type { ISetRole, ISetRoleProps } from '../../../data-structure/Interfaces';
import type { EventTypes, TargetElementTypes } from '../../../data-structure/Types';
import { COLLECTIONS, ACCESS_ITEMS, USER_ROLE, NOTIFICATIONS } from '../../../data-structure/Enums';

import './SetRole.scss';

const SetRole: React.FC<ISetRoleProps> = ({ roleUser, setRoleUser }): React.JSX.Element | null => {
  // Get user context data
  const { user, setUser } = useContext(UserContext);

  // Get access permissions
  const setOwnRole = havePermission(ACCESS_ITEMS.SetOwnRole, user?.userRole);
  const setOthersRole = havePermission(ACCESS_ITEMS.SetOthersRole, user?.userRole);

  // Return if no set role permission
  const isSelf = isOwner(user, roleUser);
  if ((isSelf && !setOwnRole) || (!isSelf && !setOthersRole)) return null;

  // Define modal state, default set as open
  const [modalOpen, setModalOpen] = useState<boolean>(true);

  // Define local states
  const [roleInput, setRoleInput] = useState<Partial<ISetRole>>({
    userRole: roleUser?.userRole ?? USER_ROLE.USER,
  });
  const [errorMessages, setErrorMessages] = useState<Partial<ISetRole>>({});

  // Get role schema and input field mapper
  const userRoleSchema = getSetRoleSchema();
  const inputFields = setRoleInputFieldMapper(roleUser?.name as string);

  // Get user collection reference
  const usersCollection: CollectionReference<DocumentData> = collection(db, COLLECTIONS.USERS);

  // On change callback
  const doChange = (e: EventTypes, error: string | null): void => {
    if ('target' in e) {
      const target = e.target as TargetElementTypes;

      setErrorMessages((prevState) => {
        return { ...prevState, [target.name]: error };
      });

      setRoleInput((prevState) => {
        return { ...prevState, [target.name]: target.value.trim() };
      });
    }
  };

  // on submission callback
  const doSubmission = async (errors: Record<string, string> | null): Promise<void> => {
    // checks for the null
    if (errors !== null) {
      // set error message
      setErrorMessages(errors as any);
    } else {
      // Check if user has role setting permission?
      if (user?.userRole !== USER_ROLE.SUPERADMIN) {
        // Closes modal window
        handleCloseModal();
        toastNotification('Sorry, you do not have permission to set role!', NOTIFICATIONS.Error);
        return;
      }

      // Search data map
      const searchData = {
        email: roleUser?.email as string,
      };

      // Fetch existing matched user
      // Reference to the Firestore document of the user
      const matchedUser = (await getMatchedDocument<typeof searchData>(
        searchData,
        usersCollection,
      )) as QueryDocumentSnapshot<DocumentData>;

      try {
        // Update the userRole field of the user
        await updateDoc(matchedUser.ref, {
          userRole: roleInput.userRole,
        });

        // If user updating their own role
        // update their jwt token and context data
        if (
          user?.email === roleUser?.email &&
          user?.userRole !== roleInput.userRole &&
          roleInput.userRole !== undefined
        ) {
          // Update user context data object
          user.userRole = roleInput.userRole;
          setUser(user);

          // Update stored jwt token data
          await storeJwtToken(user);
        }

        // Reset local state
        setRoleInput({});

        // Rreset form
        setErrorMessages({});

        toastNotification('User role updated successfully.', NOTIFICATIONS.Success);
        // Closes modal window
        handleCloseModal();
      } catch (error) {
        console.error('Error:', error);
        throw error;
      }
    }
  };

  // Modal will be opened on click set role button
  // Closes confirm modal window
  // Reset error, reset selected user, reset modal
  const handleCloseModal = (): null => {
    setErrorMessages({});
    setRoleUser(null);
    setModalOpen((prevState) => !prevState);
    return null;
  };

  return (
    <Modal isOpen={modalOpen} onClose={handleCloseModal}>
      <div>
        <form
          id="set-role-form"
          onSubmit={async (e) => {
            await handleSubmission<Partial<ISetRole>>(e, userRoleSchema, roleInput, doSubmission);
          }}
          className="set-role"
        >
          <h2>Set User Role</h2>
          {inputFields.map((field) => (
            <div key={field.name}>
              <div>
                <div>
                  <label className="label">{field.label}</label>
                </div>
                <div>
                  <select
                    className="set-role-input"
                    onChange={(e) => {
                      handleChange(e, userRoleSchema, doChange);
                    }}
                    name={field.name}
                    value={roleInput.userRole}
                  >
                    {Object.values(USER_ROLE).map((role) => (
                      <option key={role} value={role}>
                        {role.toUpperCase()}
                      </option>
                    ))}
                  </select>
                  {typeof field.description === 'string' && (
                    <p className="description">{field.description}</p>
                  )}
                  <p className="error">{errorMessages[field.name as keyof ISetRole]}</p>
                </div>
              </div>
              <div style={{ clear: 'both' }}></div>
            </div>
          ))}

          <div className="buttons">
            <button className="button-action" type="submit">
              Set Role
            </button>
            <button onClick={handleCloseModal} className="button-action">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default SetRole;
