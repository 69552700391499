import { COURSE_LENGTH } from '../data-structure/Enums';
import type { IFieldsForInput } from '../data-structure/Interfaces';

export const courseInputFieldMapper = (): IFieldsForInput[] => {
  const inputFields: IFieldsForInput[] = [
    {
      name: 'name',
      type: 'text',
      label: 'Course Name',
      placeholder: 'Name of Course',
    },
    {
      name: 'instructor',
      type: 'text',
      label: 'Instructor',
      placeholder: 'Instructor',
    },
    {
      name: 'courseUnit',
      type: 'dropdown',
      label: 'Course Length',
      placeholder: 'Unit',
      options: [COURSE_LENGTH.HOURS, COURSE_LENGTH.DAYS, COURSE_LENGTH.WEEKS, COURSE_LENGTH.MONTHS],
    },
    {
      name: 'link',
      type: 'text',
      label: 'Course Link',
      placeholder: 'Course Link',
    },
    {
      name: 'supplier',
      type: 'text',
      label: 'Supplier',
      placeholder: 'Supplier',
    },
    {
      name: 'skillsRequired',
      type: 'text',
      label: 'Skills Required',
      placeholder: 'Skills Required',
    },
    {
      name: 'skillsLearned',
      type: 'text',
      label: 'Skills Learned',
      placeholder: 'Skills Learned',
    },
    {
      name: 'description',
      type: 'text',
      label: 'Description',
      placeholder: 'Description',
    },
    {
      name: 'membershipType',
      type: 'checkbox',
      label: 'Does the course require a membership?',
      placeholder: 'Membership Required?',
      checkOptionLabel: 'Yes',
      description: 'Check, if the course requires membership.',
    },
  ];

  return inputFields;
};

export const setPasswordInputFieldMapper = (): IFieldsForInput[] => {
  const inputFields: IFieldsForInput[] = [
    {
      name: 'password',
      type: 'password',
      label: 'Password',
      placeholder: 'Password',
    },
    {
      name: 'repeat_password',
      type: 'password',
      label: 'Repeat Password',
      placeholder: 'Repeat Password',
    },
  ];

  return inputFields;
};

export const setCustomLoginInputFieldMapper = (): IFieldsForInput[] => {
  const inputFields: IFieldsForInput[] = [
    {
      name: 'email',
      type: 'text',
      label: 'Email',
      placeholder: 'Email',
    },
    {
      name: 'password',
      type: 'password',
      label: 'Password',
      placeholder: 'Password',
    },
  ];

  return inputFields;
};

export const setRoleInputFieldMapper = (name: string): IFieldsForInput[] => {
  const inputFields: IFieldsForInput[] = [
    {
      name: 'userRole',
      type: 'select',
      label: 'User Role',
      placeholder: 'User Role',
      description: `Select a role for ${name}.`,
    },
  ];

  return inputFields;
};

export const courseReviewFieldMapper = (): IFieldsForInput[] => {
  const inputFields: IFieldsForInput[] = [
    {
      name: 'heading',
      type: 'text',
      label: 'Heading:',
      placeholder: 'Heading of review',
    },
    {
      name: 'rate',
      type: 'number',
      label: 'Your rating:',
      placeholder: 'Your rating',
    },
    {
      name: 'comment',
      type: 'textarea',
      label: 'Comment:',
      placeholder: 'Write your comment',
    },
  ];

  return inputFields;
};
