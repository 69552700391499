import React, { useCallback, useContext } from 'react';

import { Modal } from '../../../components/Modals';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { CourseContext } from '../../../contexts/CourseContext';
import { MODAL_SIZE } from '../../../data-structure/Enums';

const FilterModal: React.FC = (): React.JSX.Element | null => {
  // Define modal state, default set as open
  const { showFilterByModal, setFilterByModalVisible, tags, setTags } = useContext(GlobalContext);
  const { courses } = useContext(CourseContext) ?? {};

  const addTag = useCallback(
    (tag: string) => () => {
      if (!tags.includes(tag)) {
        setTags((prevTags) => [...prevTags, tag]);
      }
    },
    [tags],
  );

  const removeTag = useCallback(
    (tag: string) => () => {
      setTags((prevTags) => prevTags.filter((t) => t !== tag));
    },
    [tags],
  );

  const learnedSkillsArray = courses
    ?.map((course) => course.skillsLearned)
    .filter(function (item, pos, self) {
      return self.indexOf(item) === pos;
    });

  const supplierArray = courses
    ?.map((course) => course.supplier)
    .filter(function (item, pos, self) {
      return self.indexOf(item) === pos;
    });

  const handleCloseModal = (): null => {
    setFilterByModalVisible(false);
    return null;
  };

  return (
    <Modal isOpen={showFilterByModal} size={MODAL_SIZE.LARGE} onClose={handleCloseModal}>
      <h1 style={{ padding: '1rem 1rem 0 1rem' }}>Filter by</h1>
      <form action="">
        <div style={{ display: 'flex', flexDirection: 'row', padding: '1rem' }}>
          <div style={{ maxWidth: '50%' }}>
            <h2>Skills learned</h2>
            {learnedSkillsArray?.map((learnedSkill, i) => (
              <div key={i} style={{ margin: '12px 0' }}>
                <input
                  type="checkbox"
                  id={learnedSkill}
                  name={learnedSkill}
                  value={learnedSkill}
                  style={{ marginRight: '6px' }}
                  onChange={
                    tags.find((tag) => tag === learnedSkill)
                      ? removeTag(learnedSkill)
                      : addTag(learnedSkill)
                  }
                  checked={!!tags.find((tag) => tag === learnedSkill)}
                />
                <label>{learnedSkill}</label>
                <br />
              </div>
            ))}
          </div>
          <div style={{ maxWidth: '50%' }}>
            <h2>Supplier</h2>
            {supplierArray?.map((supplier, i) => (
              <div key={i} style={{ margin: '12px 0' }}>
                <input
                  type="checkbox"
                  id={supplier}
                  name={supplier}
                  value={supplier}
                  style={{ marginRight: '6px' }}
                  onChange={
                    tags.find((tag) => tag === supplier) ? removeTag(supplier) : addTag(supplier)
                  }
                  checked={!!tags.find((tag) => tag === supplier)}
                />
                <label>{supplier}</label>
                <br />
              </div>
            ))}
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default FilterModal;
