import React, { useContext } from 'react';

import List from '../List';
import { FilterBar } from '../../Filter';
import { havePermission } from '../../../access/Permission';
import { UserContext } from '../../../contexts/UserContext';
import { ACCESS_ITEMS, USER_SORT_OPTIONS } from '../../../data-structure/Enums';

import './Information.scss';

const Information: React.FC = (): React.JSX.Element => {
  // Get user context data
  const { user } = useContext(UserContext);

  // Get access permission
  const canSearchUser = havePermission(ACCESS_ITEMS.CanSearchUser, user?.userRole);

  return (
    <div className="user-component">
      <FilterBar
        itemName="Users"
        canSearch={canSearchUser}
        canFilter={false}
        sortOptions={USER_SORT_OPTIONS}
      >
        <List />
      </FilterBar>
    </div>
  );
};

export default Information;
