import React, { useState, useContext } from 'react';
import {
  updateDoc,
  collection,
  type DocumentData,
  type CollectionReference,
  type QueryDocumentSnapshot,
} from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

import { db } from '../../../firebase-config';
import { havePermission } from '../../../access/Permission';
import { UserContext } from '../../../contexts/UserContext';
import { getSetPasswordSchema } from '../../../validation/Rules';
import { handleChange, handleSubmission } from '../../../form/Handlers';
import { setPasswordInputFieldMapper } from '../../../form/InputFieldMappers';
import { hashedPassword } from '../../../encryption/Hash';
import { getMatchedDocument } from '../../../firestoredb/Queries';
import { toastNotification } from '../../../utils/Toast';
import type { ISetPassword } from '../../../data-structure/Interfaces';
import type { EventTypes, TargetElementTypes } from '../../../data-structure/Types';
import { COLLECTIONS, ACCESS_ITEMS, NOTIFICATIONS } from '../../../data-structure/Enums';

import './SetPassword.scss';

const SetPassword: React.FC = (): React.JSX.Element | null => {
  // Get navigator and user context data
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  // Get access permissions
  const canSetPassword = havePermission(ACCESS_ITEMS.CanSetPassword, user?.userRole);
  const viewSetPassword = havePermission(ACCESS_ITEMS.ViewSetPasswordPage, user?.userRole);

  // Return if no delete user permission
  if (!canSetPassword || !viewSetPassword) return null;

  // define local states
  const [passwordInput, setPasswordInput] = useState<Partial<ISetPassword>>({});
  const [errorMessages, setErrorMessages] = useState<Partial<ISetPassword>>({});

  // get input field mapper
  const inputFields = setPasswordInputFieldMapper();

  // Get user collection reference
  const usersCollection: CollectionReference<DocumentData> = collection(db, COLLECTIONS.USERS);

  // on change callback
  const doChange = (e: EventTypes, error: string | null): void => {
    if ('target' in e) {
      const target = e.target as TargetElementTypes;

      setErrorMessages((prevState) => {
        return { ...prevState, [target.name]: error };
      });

      setPasswordInput((prevState) => {
        return { ...prevState, [target.name]: target.value.trim() };
      });
    }
  };

  // on submission callback
  const doSubmission = async (errors: Record<string, string> | null): Promise<void> => {
    // checks for the null
    if (errors !== null) {
      // set error message
      setErrorMessages(errors as any);
    } else {
      // User email as ID
      const userEmail = user?.email as string;

      // Search data map
      const searchData = {
        email: userEmail,
      };

      // Fetched existing matched user
      // Reference to the Firestore document of the user
      const matchedUser = (await getMatchedDocument<typeof searchData>(
        searchData,
        usersCollection,
      )) as QueryDocumentSnapshot<DocumentData>;

      try {
        // Get hashed password
        const userPassword = await hashedPassword(passwordInput.password ?? '');

        // Update the password field of the user
        await updateDoc(matchedUser.ref, {
          password: userPassword,
        });

        // reset local state
        setPasswordInput({});

        // reset form
        setErrorMessages({});
        toastNotification('Password updated successfully!', NOTIFICATIONS.Success);
        navigate('/courses');
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  const handleShowForm = (): void => {
    setErrorMessages({});
    navigate(-1);
  };

  return (
    <div className="set-password-container">
      <form
        id="set-password-form"
        onSubmit={async (e) => {
          await handleSubmission<Partial<ISetPassword>>(
            e,
            getSetPasswordSchema(),
            passwordInput,
            doSubmission,
          );
        }}
        className="set-password"
      >
        <h2>Set Password</h2>
        {inputFields.map((field) => (
          <div key={field.name}>
            <div>
              <div>
                <label className="label">{field.label}</label>
              </div>
              <div>
                <input
                  className="set-password-input"
                  onChange={(e) => {
                    handleChange(e, getSetPasswordSchema(), doChange);
                  }}
                  type={field.type}
                  name={field.name}
                  placeholder={field.placeholder}
                ></input>
                {typeof field.description === 'string' && (
                  <p className="description">{field.description}</p>
                )}
                <p className="error">{errorMessages[field.name as keyof ISetPassword]}</p>
              </div>
            </div>
            <div style={{ clear: 'both' }}></div>
          </div>
        ))}

        <div className="buttons">
          <button className="button-action" type="submit">
            Set Password
          </button>
          <button onClick={handleShowForm} className="button-action">
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default SetPassword;
