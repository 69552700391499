import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { collection, onSnapshot } from 'firebase/firestore';

import { Rate, Statistics } from '../../Ratings';
import { SearchContext } from '../../Filter/FilterBar';
import { db } from '../../../firebase-config';
import { CourseContext } from '../../../contexts/CourseContext';
import type { ICourse } from '../../../data-structure/Interfaces';
import { COLLECTIONS, MEMBERSHIP_TYPES } from '../../../data-structure/Enums';

import './Information.scss';

const Information: React.FC<{
  course: ICourse;
  ratings?: boolean;
  statistics?: boolean;
}> = ({ course, ratings = false, statistics = false }): React.JSX.Element => {
  // Get context data
  const searchInput = useContext(SearchContext);
  const courseContext = useContext(CourseContext);

  useEffect(
    () =>
      onSnapshot(collection(db, COLLECTIONS.COURSES), (snapshot) => {
        courseContext?.setCourses(
          snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })) as ICourse[],
        );
      }),
    [searchInput.searchWord?.value, searchInput.sortType?.value],
  );

  const correctCourseLengthWithUnit = (): string => {
    return parseInt(course.courseLength) <= 1
      ? `${course.courseLength} ${course.courseUnit}`
      : `${course.courseLength} ${course.courseUnit}s`;
  };

  const getSkillContents = (skills: string, label: string): React.JSX.Element => (
    <div>
      <div>{label}</div>
      <div>
        {skills.split(',').map((courseskill) => (
          <div key={course.skillsRequired.indexOf(courseskill)}>
            <strong>{courseskill}</strong>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="course-information">
      <div>
        <div>
          <div>
            <Link target="_blank" to={course.link}>
              <h2>{course.name}</h2>
            </Link>
          </div>
          <div>
            <div>
              <p>
                {course.instructor}, {course.supplier}
              </p>
              <p>
                <a href={course.link} target="_blank" rel="noreferrer">
                  Link to course
                </a>
              </p>
              <p>{course.description}</p>
              <p>
                <strong>Course Length:</strong> {correctCourseLengthWithUnit()}
              </p>
              <p>
                <strong>Membership Requirement:</strong>{' '}
                {course.membershipType === MEMBERSHIP_TYPES.PAID
                  ? 'Only for paid members'
                  : 'Free, no membership needed'}
              </p>
            </div>
          </div>
        </div>
        <div>
          {!statistics && (
            <div className="skills-pan">
              {getSkillContents(course.skillsRequired, 'Skills Required:')}
              {getSkillContents(course.skillsLearned, 'Skills Learned:')}
            </div>
          )}
          {ratings && <Rate course={course} updateRate={() => {}} />}
          {statistics && <Statistics courseId={course.id} />}
        </div>
      </div>
      {statistics && (
        <div>
          {getSkillContents(course.skillsRequired, 'Skills Required:')}
          {getSkillContents(course.skillsLearned, 'Skills Learned:')}
        </div>
      )}
    </div>
  );
};

export default Information;
