import React, { useState } from 'react';
import type Joi from 'joi';

import { handleChange, handleSubmission } from '../../form/Handlers';
import { courseInputFieldMapper } from '../../form/InputFieldMappers';
import type { IFieldsForInput, ICourse } from '../../data-structure/Interfaces';
import type { EventTypes, TargetElementTypes } from '../../data-structure/Types';
import { MEMBERSHIP_TYPES } from '../../data-structure/Enums';

import './Form.scss';

const Form: React.FC<{
  primaryButtonAction: any;
  secondaryButtonAction: any;
  onSubmitAction: (input: Partial<ICourse>) => Promise<void>;
  onCancelAction: () => void;
  getCourseSchema: Joi.ObjectSchema<any>;
  initialInput?: Partial<ICourse>;
}> = ({
  primaryButtonAction,
  secondaryButtonAction,
  onSubmitAction,
  onCancelAction,
  getCourseSchema,
  initialInput,
}): React.JSX.Element => {
  // define local states
  const [courseInput, setCourseInput] = useState<Partial<ICourse>>(initialInput ?? {});
  const [errorMessages, setErrorMessages] = useState<Partial<Record<keyof ICourse, string>>>({});

  // get input field mapper
  const inputFields = courseInputFieldMapper();

  // on change callback
  const doChange = (e: EventTypes, error: string | null): void => {
    if ('target' in e) {
      const target = e.target as TargetElementTypes;

      setErrorMessages((prevState) => {
        return { ...prevState, [target.name]: error };
      });

      setCourseInput((prevState) => {
        if (target.type === 'checkbox' && target instanceof HTMLInputElement) {
          return {
            ...prevState,
            [target.name]: target.checked ? MEMBERSHIP_TYPES.PAID : MEMBERSHIP_TYPES.FREE,
          };
        } else if (['text', 'number', 'email'].includes(target.type)) {
          return { ...prevState, [target.name]: target.value };
        } else {
          return { ...prevState, [target.name]: target.value };
        }
      });
    }
  };

  const getInputElement = (field: IFieldsForInput): React.JSX.Element => (
    <div key={field.name}>
      <div>
        <label className="label">{field.label}</label>
      </div>
      <div>
        {field && field?.name === 'description' ? (
          <textarea
            className="create-course-input"
            rows={5}
            cols={33}
            placeholder={field.label}
            name={field.name}
            value={(courseInput as any)[field.name] || ''}
            onChange={(e) => {
              handleChange(e, getCourseSchema, doChange);
            }}
          ></textarea>
        ) : (
          <input
            className="create-course-input"
            onChange={(e) => {
              handleChange(e, getCourseSchema, doChange);
            }}
            type={field.type}
            value={(courseInput as any)[field.name] || ''}
            name={field.name}
            placeholder={field.placeholder}
          ></input>
        )}
        {typeof field.description === 'string' && (
          <p className="description">{field.description}</p>
        )}
        <p className="error">{errorMessages[field.name as keyof ICourse]}</p>
      </div>
    </div>
  );

  const getCheckboxElement = (field: IFieldsForInput): React.JSX.Element => (
    <div key={field.name}>
      <label className="label-checkbox">{field.label}</label>
      <input
        className="edit-course-checkbox"
        onChange={(e) => {
          handleChange(e, getCourseSchema, doChange);
        }}
        type={field.type}
        name={field.name}
        checked={courseInput.membershipType === MEMBERSHIP_TYPES.PAID || false}
        placeholder={field.placeholder}
      ></input>
      {field.checkOptionLabel && <span>&nbsp;&nbsp;{field.checkOptionLabel}</span>}
      {typeof field.description === 'string' && <p className="description">{field.description}</p>}
      <p className="error">{errorMessages[field.name as keyof ICourse]}</p>
    </div>
  );

  const getDropdownElement = (field: IFieldsForInput): React.JSX.Element => (
    <div key={field.name}>
      <label className="label-dropdown">{field.label}</label>
      <div className="dropdown-element">
        <input
          className="create-course-input"
          onChange={(e) => {
            handleChange(e, getCourseSchema, doChange);
          }}
          type="number"
          name="courseLength"
          placeholder="Length"
          style={{ marginRight: '1rem' }}
          value={courseInput.courseLength}
        ></input>
        <select
          className="create-course-input"
          name="courseUnit"
          value={courseInput.courseUnit}
          onChange={(e) => {
            handleChange(e, getCourseSchema, doChange);
          }}
        >
          {field.options?.map((option) => (
            <option key={option} value={option} selected>
              {option}
            </option>
          ))}
        </select>
      </div>
      {typeof field.description === 'string' && <p className="description">{field.description}</p>}
      <p className="error">{errorMessages[field.name as keyof ICourse]}</p>
    </div>
  );

  return (
    <form
      id="edit-course-form"
      onSubmit={async (e) => {
        await handleSubmission<ICourse>(
          e,
          getCourseSchema,
          courseInput as ICourse,
          async (errors) => {
            if (errors?.length) {
              setErrorMessages(errors);
            } else {
              await onSubmitAction(courseInput);
            }
          },
        );
      }}
      className="edit-course"
    >
      {inputFields.map((field) => {
        if (['text', 'email', 'number'].includes(field.type)) {
          return getInputElement(field);
        } else if (field.type === 'checkbox') {
          return getCheckboxElement(field);
        } else if (field.type === 'dropdown') {
          return getDropdownElement(field);
        }
        return null;
      })}

      <div className="footer">
        <button className="button-action" type="submit">
          {primaryButtonAction}
        </button>
        <button onClick={onCancelAction} className="button-action">
          {secondaryButtonAction}
        </button>
      </div>
    </form>
  );
};

export default Form;
