import React, { useState, useEffect } from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';

import type { ProtectedRoutePropsType } from '../../data-structure/Types';

const ProtectedRoute: React.FC<ProtectedRoutePropsType> = ({
  user,
  canView = true,
}): React.JSX.Element => {
  const location = useLocation();
  const [authResolved, setAuthResolved] = useState(false);

  useEffect(() => {
    // Simulate an authentication delay
    setTimeout(() => {
      setAuthResolved(true);
    }, 50);
  }, []);

  if (!authResolved) {
    // While authentication is pending,
    // display a loading indicator or message
    return <div>Loading...</div>;
  }

  if (user !== undefined && user !== null) {
    // Redirect to not found page if no view permission
    if (!canView) {
      return <Navigate to={'/not-found'} state={{ from: location }} />;
    }

    // The user is authenticated, allow access to nested routes
    return <Outlet />;
  }

  // Redirect unauthenticated users to the login page
  return <Navigate to={'/login'} state={{ from: location }} />;
};

export default ProtectedRoute;
