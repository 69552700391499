/**
 * Converts seconds to a "YYYY-MM-DD" formatted date string.
 * @param seconds - The number of seconds to be converted.
 * @returns A string representing the date in "YYYY-MM-DD" format.
 */
export const getDateString = (seconds: number): string => {
  const date = new Date(seconds * 1000);

  const year = date.getFullYear();
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);

  return `${year}-${month}-${day}`;
};
