import sign from 'jwt-encode';

import { storeData } from '../indexed-db/Connector';
import type { IAuthUser } from '../data-structure/Interfaces';
import { INDEXED_DB_OPTIONS } from '../data-structure/Enums';

/**
 * Stores a JWT token associated with a user in a secure storage mechanism.
 * In our case, we are storing jwt token data into IndexedDB
 *
 * @param userData - User data containing the user information to be stored.
 * @returns A Promise that resolves when the token is successfully stored.
 * @throws An error if there are issues with token storage.
 */
export async function storeJwtToken(userData: Partial<IAuthUser>): Promise<void> {
  // Get jwt token secret
  const jwtSecret = process.env.REACT_APP_JWT_TOKEN_SECRET ?? '';

  // Encodes user data into jwt token
  const encodedJwtToken: string = sign(userData, jwtSecret);

  // Store new user data into IndexedDB store
  // Store user credentials to in memory db
  await storeData<typeof encodedJwtToken>({
    key: INDEXED_DB_OPTIONS.JWT_KEY_NAME,
    data: encodedJwtToken,
    storeName: INDEXED_DB_OPTIONS.JWT_STORE_NAME,
    databaseName: INDEXED_DB_OPTIONS.DATABASE_NAME,
  });
}
