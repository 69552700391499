export const COURSE_SORT_OPTIONS = {
  NAME_OF_COURSE: 'Name of course',
  COURSE_SUPPLIER: 'Course supplier',
  DESCENDING_COURSE_LENGTH: 'Descending course length',
  ASCENDING_COURSE_LENGTH: 'Ascending course length',
  REVIEW_DATE: 'Review date',
  NUMBER_OF_STARS: 'Number of stars',
};

export const USER_SORT_OPTIONS = {
  ASCENDING_USER_NAME: 'User name [ascending]',
  DESCENDING_USER_NAME: 'User name [descending]',
  ASCENDING_USER_EMAIL: 'Ascending user email [ascending]',
  DESCENDING_USER_EMAIL: 'Descending user email [descending]',
  ASCENDING_USER_LOCALE: 'Ascending user locale [ascending]',
  DESCENDING_USER_LOCALE: 'Descending user locale [descending]',
};

export const MEMBERSHIP_TYPES = {
  FREE: 'Free',
  PAID: 'Paid',
};

export const INDEXED_DB_OPTIONS = {
  JWT_KEY_NAME: 'cxUserJwtToken',
  JWT_STORE_NAME: 'jwtTokens',
  DATABASE_NAME: 'course-explorer-db',
};

export const COLLECTIONS = {
  USERS: 'Users',
  COURSES: 'Courses',
  COURSE_EVALUATION: 'Course_Evaluation',
};

export enum MODAL_SIZE {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  XLARGE = 'xlarge',
}

export enum USER_ROLE {
  USER = 'user',
  ADMIN = 'admin',
  SUPERADMIN = 'superadmin',
}

export enum ENVIRONMENT {
  TEST = 'test',
  DEVELOPMENT = 'development',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export enum ACCESS_ITEMS {
  UseGoogleLogin = 'UseGoogleLogin',
  UseCustomLogin = 'UseCustomLogin',
  CanSetPassword = 'CanSetPassword',
  ViewOwnInfo = 'ViewOwnInfo', // own account info
  ViewOthersInfo = 'ViewOthersInfo', // others account info
  ViewUsersList = 'ViewUsersList',
  CanSearchUser = 'CanSearchUser',
  CreateOwnAccount = 'CreateOwnAccount',
  CreateOthersAccount = 'CreateOthersAccount',
  EditOwnAccount = 'EditOwnAccount',
  EditOthersAccount = 'EditOthersAccount',
  DeleteOwnAccount = 'DeleteOwnAccount',
  DeleteOthersAccount = 'DeleteOthersAccount',
  SetOwnRole = 'SetOwnRole',
  SetOthersRole = 'SetOthersRole',
  ViewOwnCourse = 'ViewOwnCourse', // own course info
  ViewOthersCourse = 'ViewOthersCourse', // others course info
  ViewCoursesList = 'ViewCoursesList',
  CanSearchCourse = 'CanSearchCourse',
  CreateOwnCourse = 'CreateOwnCourse',
  CreateOthersCourse = 'CreateOthersCourse',
  EditOwnCourse = 'EditOwnCourse',
  EditOthersCourse = 'EditOthersCourse',
  DeleteOwnCourse = 'DeleteOwnCourse',
  DeleteOthersCourse = 'DeleteOthersCourse',
  RateOwnCourse = 'RateOwnCourse',
  RateOthersCourse = 'RateOthersCourse',
  ViewUsersPage = 'ViewUsersPage',
  ViewCoursesPage = 'ViewCoursesPage',
  ViewSetPasswordPage = 'ViewSetPasswordPage',
}

export enum COURSE_LENGTH {
  HOURS = 'Hour',
  DAYS = 'Day',
  WEEKS = 'Week',
  MONTHS = 'Month',
}

export enum NOTIFICATIONS {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
}
