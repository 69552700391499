import React, { useState, createContext, useContext, useCallback } from 'react';

import Search from '../Search';
import SortBy from '../SortBy';
import FilterButton from '../FilterWithTags/FilterButton';
import { GlobalContext } from '../../../contexts/GlobalContext';
import type {
  ISortInput,
  ISearchInput,
  ISearchContext,
  ISearchContextProviderProps,
} from '../../../data-structure/Interfaces';

import './FilterBar.scss';

export const SearchContext = createContext<ISearchContext>({} as any);

const FilterBar: React.FC<ISearchContextProviderProps> = ({
  itemName,
  canSearch,
  canFilter = true,
  sortOptions,
  children,
}: ISearchContextProviderProps): React.JSX.Element => {
  const [searchWord, setSearchWord] = useState<ISearchInput | null>(null);
  const [sortType, setSortType] = useState<ISortInput | null>(null);
  const { tags, setTags } = useContext(GlobalContext);

  const removeTag = useCallback(
    (tag: string) => () => {
      setTags((prevTags) => prevTags.filter((t) => t !== tag));
    },
    [tags],
  );

  return (
    <>
      <SearchContext.Provider value={{ searchWord, setSearchWord, sortType, setSortType }}>
        <div className="filter-bar">
          <h1 className="filter-bar-left">{itemName}</h1>
          {canSearch && (
            <div className="filter-bar-right">
              <Search />
              <SortBy sortOptions={sortOptions} />
              {canFilter && <FilterButton />}
            </div>
          )}
        </div>
        <div className="tags-container">
          {tags.length >= 1 &&
            tags.map((tag, i) => (
              <button onClick={removeTag(tag)} key={i} className="skills-tags-button">
                {tag} &nbsp; x
              </button>
            ))}
        </div>
        {children}
      </SearchContext.Provider>
    </>
  );
};

export default FilterBar;
