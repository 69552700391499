import React, { useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import jwr_decode from 'jwt-decode';
import {
  addDoc,
  collection,
  type DocumentData,
  type CollectionReference,
  type QueryDocumentSnapshot,
} from 'firebase/firestore';
import { GoogleLogin, type CredentialResponse } from '@react-oauth/google';

import { db } from '../../../firebase-config';
import { storeJwtToken } from '../../../encryption/Jwt';
import { UserContext } from '../../../contexts/UserContext';
import { getMatchedDocument } from '../../../firestoredb/Queries';
import { type IAuthUser } from '../../../data-structure/Interfaces';
import { COLLECTIONS, USER_ROLE } from '../../../data-structure/Enums';

import './Login.scss';

const Login: React.FC = (): React.JSX.Element => {
  // Get navigator
  const navigate = useNavigate();

  // Get user context data
  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    if (user !== null && user !== undefined) {
      navigate('/courses');
    }
  }, [user, navigate]);

  // Get user collection reference
  const usersCollection: CollectionReference<DocumentData> = collection(db, COLLECTIONS.USERS);

  // Google login success handler
  const handleSuccess = async (credentialResponse: CredentialResponse): Promise<void> => {
    // JWT token credentials from google account
    const userCredentials = credentialResponse.credential ?? '';

    // Decode user jwt token data
    const userObject = jwr_decode(userCredentials);

    // Resolve all promises
    // Create user if not exists
    addUser(userObject as IAuthUser)
      .then((loggedInUser) => {
        // Set user data to user context
        setUser(loggedInUser as IAuthUser);
      })
      .catch((error: DOMException) => {
        console.error('Error storing JWT token:', error);
        throw error;
      });
  };

  // Google login error handler
  const handleError = (): void => {
    console.error('Login Failed');
  };

  // Add new user into database
  const addUser = async (userObject: IAuthUser): Promise<Partial<IAuthUser>> => {
    try {
      // Search data map
      const searchData = {
        email: userObject.email,
      };

      // Fetched existing matched user
      // Reference to the Firestore document of the user
      // Check if the document already exists
      const matchedUser = (await getMatchedDocument<typeof searchData>(
        searchData,
        usersCollection,
      )) as QueryDocumentSnapshot<DocumentData>;

      // Get user data
      const existingUser = matchedUser?.data();

      // Create new user if the user does not exist
      if (existingUser === null || existingUser === undefined) {
        const userData = {
          email: userObject.email,
          name: userObject.name,
          locale: userObject.locale,
          loginDate: new Date(),
          picture: userObject.picture,
          userRole: USER_ROLE.USER,
          deleted: false,
        };

        // Create new user
        await addDoc(usersCollection, { ...userData, password: '' });

        // Store jwt token into IndexedDB
        await storeJwtToken(userData);

        // Return user data
        return userData;
      } else {
        // Store existing user data into IndexedDB store
        // Delete sensitive data from user object
        delete existingUser?.password;

        // Store jwt token into IndexedDB
        await storeJwtToken(existingUser);

        // Return user data
        return existingUser;
      }
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  return (
    <div className="login-box">
      <div className="heading">
        <h1 className="title">Sign in to Qlue Course Explorer</h1>
        <body>Login to start saving and rating your course reviews today!</body>
      </div>
      <GoogleLogin
        onSuccess={handleSuccess}
        onError={handleError}
        hosted_domain={process.env.REACT_APP_HOSTED_DOMAIN}
      />
      <div className="textbox">or</div>
      <div className="email-login">
        <Link to="/custom-login">
          <svg
            height="35px"
            id="Layer_1"
            // style="enable-background:new 0 0 512 512;"
            version="1.1"
            viewBox="0 0 512 512"
            width="35px"
            // xml:space="preserve"
            xmlns="http://www.w3.org/2000/svg"
            // xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g>
              <path d="M67,148.7c11,5.8,163.8,89.1,169.5,92.1c5.7,3,11.5,4.4,20.5,4.4c9,0,14.8-1.4,20.5-4.4c5.7-3,158.5-86.3,169.5-92.1   c4.1-2.1,11-5.9,12.5-10.2c2.6-7.6-0.2-10.5-11.3-10.5H257H65.8c-11.1,0-13.9,3-11.3,10.5C56,142.9,62.9,146.6,67,148.7z" />
              <path d="M455.7,153.2c-8.2,4.2-81.8,56.6-130.5,88.1l82.2,92.5c2,2,2.9,4.4,1.8,5.6c-1.2,1.1-3.8,0.5-5.9-1.4l-98.6-83.2   c-14.9,9.6-25.4,16.2-27.2,17.2c-7.7,3.9-13.1,4.4-20.5,4.4c-7.4,0-12.8-0.5-20.5-4.4c-1.9-1-12.3-7.6-27.2-17.2l-98.6,83.2   c-2,2-4.7,2.6-5.9,1.4c-1.2-1.1-0.3-3.6,1.7-5.6l82.1-92.5c-48.7-31.5-123.1-83.9-131.3-88.1c-8.8-4.5-9.3,0.8-9.3,4.9   c0,4.1,0,205,0,205c0,9.3,13.7,20.9,23.5,20.9H257h185.5c9.8,0,21.5-11.7,21.5-20.9c0,0,0-201,0-205   C464,153.9,464.6,148.7,455.7,153.2z" />
            </g>
          </svg>
          Sign in with Email
        </Link>
      </div>
    </div>
  );
};

export default Login;
