import {
  query,
  where,
  getDocs,
  type Query,
  type QuerySnapshot,
  type DocumentData,
  type QueryConstraint,
  type CollectionReference,
  type QueryDocumentSnapshot,
} from 'firebase/firestore';

/**
 * Retrieves a document from a Firestore collection that matches the provided search data.
 *
 * @template T - The type of the search data.
 * @param {T} searchData - The search data to match against Firestore documents.
 * @param {CollectionReference<DocumentData>} collection - The Firestore collection reference to search in.
 * @returns {Promise<DocumentData | null>} - A promise that resolves to the matched document or null if no match is found.
 */
export const getMatchedDocument = async <T>(
  searchData: T,
  collection: CollectionReference<DocumentData>,
): Promise<QueryDocumentSnapshot<DocumentData> | null> => {
  try {
    // Get snapshot documents
    const querySnapshotDocs = await getMatchedDocuments<T>(searchData, collection);
    return querySnapshotDocs?.[0] ?? null;
  } catch (error) {
    console.error('Error getting documents:', error);
    throw error;
  }
};

/**
 * Retrieves queried documents from a Firestore collection that matches the provided search data.
 *
 * @template T - The type of the search data.
 * @param {T} searchData - The search data to match against Firestore documents.
 * @param {CollectionReference<DocumentData>} collection - The Firestore collection reference to search in.
 * @returns {Promise<DocumentData | null>} - A promise that resolves to the matched documents or null if no match is found.
 */
export const getMatchedDocuments = async <T>(
  searchData: T,
  collection: CollectionReference<DocumentData>,
): Promise<Array<QueryDocumentSnapshot<DocumentData>> | null> => {
  try {
    // Prepare query constraints array
    const queryConstraints: QueryConstraint[] = [];
    for (const [key, value] of Object.entries(searchData as any)) {
      queryConstraints.push(where(key, '==', value ?? null));
    }

    // Gets query data
    const queryData: Query<DocumentData> = query(collection, ...queryConstraints);

    // Gets snapshot based on query data
    const querySnapshot: QuerySnapshot<DocumentData> = await getDocs(queryData);

    if (querySnapshot.size === 0) {
      console.warn('No matching documents.');
      return null;
    }

    // Assuming only one document with the given data
    return querySnapshot.docs;
  } catch (error) {
    console.error('Error getting documents:', error);
    throw error;
  }
};
