import React, { useContext, useState } from 'react';
import {
  updateDoc,
  collection,
  type DocumentData,
  type CollectionReference,
  type QueryDocumentSnapshot,
} from 'firebase/firestore';

import { ConfirmModal } from '../../Modals';
import { db } from '../../../firebase-config';
import { isOwner, havePermission } from '../../../access/Permission';
import { UserContext } from '../../../contexts/UserContext';
import { getMatchedDocument } from '../../../firestoredb/Queries';
import { toastNotification } from '../../../utils/Toast';
import type { IDeleteUserProps } from '../../../data-structure/Interfaces';
import { COLLECTIONS, ACCESS_ITEMS, USER_ROLE, NOTIFICATIONS } from '../../../data-structure/Enums';

import './Delete.scss';

const DeleteUser: React.FC<IDeleteUserProps> = ({
  deleteUser,
  setDeleteUser,
}): React.JSX.Element | null => {
  // Get current user context data
  const { user } = useContext(UserContext);

  // Get access permissions
  const deleteOwn = havePermission(ACCESS_ITEMS.DeleteOwnAccount, user?.userRole);
  const deleteOthers = havePermission(ACCESS_ITEMS.DeleteOthersAccount, user?.userRole);

  // Return if no delete user permission
  const isSelf = isOwner(user, deleteUser);
  if ((isSelf && !deleteOwn) || (!isSelf && !deleteOthers)) return null;

  // Define modal state, default set as open
  const [modalOpen, setModalOpen] = useState<boolean>(true);

  // Get user collection reference
  const usersCollection: CollectionReference<DocumentData> = collection(db, COLLECTIONS.USERS);

  // Check if user deleting themselves?
  let deletingOwnAccount: boolean = false;
  if (user?.email === deleteUser?.email) {
    deletingOwnAccount = true;
  }

  //  Handle delete user
  const handleDelete = async (): Promise<void> => {
    // Check if user deleting themselves?
    if (deletingOwnAccount) {
      // Closes confirm modal window
      // Reset delete user, reset modal
      handleCloseModal();
      toastNotification('Sorry, you cannot delete your own account!', NOTIFICATIONS.Error);
      return;
    }

    // Check if user has delete permission?
    if (user?.userRole !== USER_ROLE.SUPERADMIN) {
      // Closes confirm modal window
      // Reset delete user, reset modal
      handleCloseModal();
      toastNotification('Sorry, you do not have permission to delete user!', NOTIFICATIONS.Error);
      return;
    }

    // Search data map
    const searchData = {
      email: deleteUser?.email as string,
    };

    // Fetch existing matched user
    // Reference to the Firestore document of the user
    const matchedUser = (await getMatchedDocument<typeof searchData>(
      searchData,
      usersCollection,
    )) as QueryDocumentSnapshot<DocumentData>;

    try {
      // Delete the user information
      await updateDoc(matchedUser.ref, {
        name: 'Deleted User',
        email: '',
        locale: '',
        deleted: true,
        password: '',
        picture: '',
        userRole: '',
      });

      toastNotification('User account deleted successfully!', NOTIFICATIONS.Success);

      // Closes confirm modal window
      // Reset delete user, reset modal
      handleCloseModal();
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  // Modal will be opened on click delete button
  // Closes confirm modal window
  // Reset delete user, reset modal
  const handleCloseModal = (): null => {
    setDeleteUser(null);
    setModalOpen((prevState) => !prevState);
    return null;
  };

  return (
    <ConfirmModal
      title="Delete User Confirmation"
      description={
        !deletingOwnAccount
          ? `Are you sure you want to delete ${deleteUser?.name}?`
          : 'Sorry, you cannot delete your own account!'
      }
      isOpen={modalOpen}
      onConfirm={handleDelete}
      onClose={handleCloseModal}
      yesButtonDisabled={deletingOwnAccount}
    />
  );
};

export default DeleteUser;
