import React, { useContext, useEffect, useState } from 'react';
import { collection, onSnapshot } from 'firebase/firestore';
import { FiX } from 'react-icons/fi';

import Details from '../Details';
import DeleteCourse from '../Delete';
import Rate from '../../Ratings/Rate';
import { SearchContext } from '../../Filter/FilterBar';
import { db } from '../../../firebase-config';
import { isOwner, havePermission } from '../../../access/Permission';
import { UserContext } from '../../../contexts/UserContext';
import { CourseContext } from '../../../contexts/CourseContext';
import type { ICourse } from '../../../data-structure/Interfaces';
import { ACCESS_ITEMS, COLLECTIONS, MEMBERSHIP_TYPES } from '../../../data-structure/Enums';

import './Card.scss';

const Card: React.FC<{
  course: ICourse;
}> = ({ course }): React.JSX.Element => {
  // Get context data
  const { user } = useContext(UserContext);
  const searchInput = useContext(SearchContext);
  const courseContext = useContext(CourseContext);

  // Update Rate
  const [rateChange, setRateChange] = useState<number>(0);
  const [deleteCourse, setDeleteCourse] = useState<string>('');
  const [detailsCourse, setDetailsCourse] = useState<ICourse | null>(null);

  // Get access permission
  const courseOwner = isOwner(user, course);
  const canEditOwn = havePermission(ACCESS_ITEMS.EditOwnCourse, user?.userRole);
  const canEditOthers = havePermission(ACCESS_ITEMS.EditOthersCourse, user?.userRole);
  const canDeleteOwn = havePermission(ACCESS_ITEMS.DeleteOwnCourse, user?.userRole);
  const canDeleteOthers = havePermission(ACCESS_ITEMS.DeleteOthersCourse, user?.userRole);

  // Update rate handler
  const updateRate = (count: number): void => {
    setRateChange((current) => current + count);
  };

  useEffect(
    () =>
      onSnapshot(collection(db, COLLECTIONS.COURSES), (snapshot) => {
        courseContext?.setCourses(
          snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })) as ICourse[],
        );
      }),
    [rateChange, searchInput.searchWord?.value, searchInput.sortType?.value],
  );

  const correctCourseLengthWithUnit = (): string => {
    return parseInt(course.courseLength) <= 1
      ? `${course.courseLength} ${course.courseUnit}`
      : `${course.courseLength} ${course.courseUnit}s`;
  };

  return (
    <>
      {deleteCourse && (
        <DeleteCourse
          courseOwner={courseOwner}
          deleteCourse={deleteCourse}
          setDeleteCourse={setDeleteCourse}
        />
      )}
      {detailsCourse && <Details course={detailsCourse} setDetailsCourse={setDetailsCourse} />}
      <div className="course-card">
        <div className="course-card-top">
          <div className="course-card-top-left">
            <div className="course-title-box">
              <a
                className="course-title"
                onClick={() => {
                  setDetailsCourse(course);
                }}
              >
                <h2>{course.name}</h2>
              </a>
            </div>
            <div className="course-body">
              <div className="course-info">
                <p className="course-source">
                  {course.instructor}, {course.supplier}
                </p>
                <p className="course-description">{course.description}</p>
                <p>
                  <strong>Course Length:</strong> {correctCourseLengthWithUnit()}
                </p>
                <p>
                  <strong>Membership Requirement:</strong>{' '}
                  {course.membershipType === MEMBERSHIP_TYPES.PAID
                    ? 'Only for paid members'
                    : 'Free, no membership needed'}
                </p>
              </div>
            </div>
          </div>
          <div className="course-card-top-right">
            <div className="button-container-group">
              {((courseOwner && canEditOwn) || (!courseOwner && canEditOthers)) && (
                <button
                  className="button-edit-course"
                  onClick={() => {
                    scroll(0, 0);
                    courseContext?.setEditingCourseId?.(course.id);
                  }}
                >
                  Edit
                </button>
              )}
              {((courseOwner && canDeleteOwn) || (!courseOwner && canDeleteOthers)) && (
                <button
                  className="button-delete-course"
                  onClick={() => {
                    setDeleteCourse(course.id);
                  }}
                >
                  <FiX />
                </button>
              )}
            </div>
            <Rate course={course} updateRate={updateRate} />
          </div>
        </div>
        <div className="course-card-bottom">
          <div className="course-card-bottom-left">
            <div className="course-skills-title">Skills Required:</div>
            <div className="course-skills-pill-container">
              {course.skillsRequired.split(',').map((courseskill) => (
                <div
                  className="course-skills-pill-required"
                  key={course.skillsRequired.indexOf(courseskill)}
                >
                  <strong>{courseskill}</strong>
                </div>
              ))}
            </div>
          </div>
          <div className="course-card-bottom-right">
            <div className="course-skills-title">Skills Learned:</div>
            <div className="course-skills-pill-container">
              {course.skillsLearned.split(',').map((courseskill) => (
                <div
                  className="course-skills-pill-learned"
                  key={course.skillsLearned.indexOf(courseskill)}
                >
                  <strong>{courseskill}</strong>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
