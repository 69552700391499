import React, { useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { removeData } from '../../../indexed-db/Connector';
import { UserContext } from '../../../contexts/UserContext';
import { INDEXED_DB_OPTIONS } from '../../../data-structure/Enums';

import './Logout.scss';

const Logout: React.FC = (): React.JSX.Element => {
  // Get user context data
  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    // Removes logged in user data from jwt token
    // Also unset logged in user from user context
    const removeAuthUser = async (): Promise<void> => {
      // Removes user data from jwt token
      const removed = await removeData({
        key: INDEXED_DB_OPTIONS.JWT_KEY_NAME,
        storeName: INDEXED_DB_OPTIONS.JWT_STORE_NAME,
        databaseName: INDEXED_DB_OPTIONS.DATABASE_NAME,
      });

      if (removed) {
        // unsets user data from user context
        setUser(null);
      }
    };

    // If context user is available, remove jwt token
    if (user !== null && user !== undefined) {
      void removeAuthUser();
    }
  }, []);

  // Redirect the logged out user to the login page
  return <Navigate to={'/login'} />;
};

export default Logout;
