import { type FormEvent } from 'react';
import type Joi from 'joi';

import { validate, validateProparty } from '../validation/Functions';
import type { ChangeHandlerType, SubmissionHandlerType, EventTypes } from '../data-structure/Types';

export const handleChange = (
  e: EventTypes,
  schema: Joi.ObjectSchema<any>,
  doChange: ChangeHandlerType,
): void => {
  // checks data validation
  const error = validateProparty(e, schema);

  // execute callback
  doChange(e, error);
};

export const handleSubmission = async <T>(
  e: FormEvent<HTMLFormElement>,
  schema: Joi.ObjectSchema<any>,
  formData: T,
  doSubmission: SubmissionHandlerType,
): Promise<void> => {
  e.preventDefault();

  // checks data validation
  const errors = validate<T>(formData, schema);

  // execute callback
  await doSubmission(errors);
};
