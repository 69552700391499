import React from 'react';
import { Link } from 'react-router-dom';

import './NotFound.scss';

const NotFound: React.FC = (): React.JSX.Element => {
  return (
    <div className="not-found">
      <img src={`${process.env.PUBLIC_URL}/images/404-error.jpg`} alt="404 Page Not Found" />
      <br />
      <Link to="/courses">GO BACK TO HOME</Link>
    </div>
  );
};

export default NotFound;
