import React, { useState, createContext } from 'react';

import type {
  IAuthUser,
  IUserContext,
  IUserContextProviderProps,
} from '../data-structure/Interfaces';

export const UserContext: React.Context<IUserContext> = createContext<IUserContext>({} as any);

export const UserContextProvider: React.FC<IUserContextProviderProps> = ({
  children,
}: IUserContextProviderProps): React.JSX.Element => {
  const [user, setUser] = useState<IAuthUser | null>(null);
  const [users, setUsers] = useState<IAuthUser[] | null>(null);

  return (
    <UserContext.Provider value={{ user, setUser, users, setUsers }}>
      {children}
    </UserContext.Provider>
  );
};
