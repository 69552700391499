import React from 'react';
import ReactPaginate from 'react-paginate';

import { type IPaginationProps } from '../../data-structure/Interfaces';

import './Pagination.scss';

export const getPaginatedItems = <T,>(
  items: T[],
  itemsPerPage: number,
  itemOffset: number,
): T[] => {
  // Evaluates paginated items
  const endOffset = itemOffset + itemsPerPage;
  return items.slice(itemOffset, endOffset);
};

const Pagination: React.FC<any> = ({
  itemsPerPage,
  totalItems,
  setItemOffset,
}: IPaginationProps): React.JSX.Element | null => {
  // Don't render if totalItems is less than or equal to itemsPerPage
  if (itemsPerPage >= totalItems) {
    return null;
  }

  // Invokes when user click to request another page.
  const handlePageClick = (e: any): void => {
    const newOffset = (e.selected * itemsPerPage) % totalItems;
    setItemOffset(newOffset);
  };

  // Evaluates page count
  const pageCount = Math.ceil(totalItems / itemsPerPage);

  const isMobile = window.innerWidth <= 500;

  return (
    <div className="pagination-wrapper">
      <ReactPaginate
        nextLabel=">>"
        onPageChange={handlePageClick}
        pageRangeDisplayed={isMobile ? 2 : 3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel="<<"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
    </div>
  );
};

export default Pagination;
