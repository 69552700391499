import React, { useContext } from 'react';

import List from '../List';
import CreateCourse from '../Create';
import EditCourse from '../Edit';
import FilterBar from '../../Filter/FilterBar';
import FilterModal from '../../Filter/FilterWithTags/FilterModal';
import { havePermission } from '../../../access/Permission';
import { UserContext } from '../../../contexts/UserContext';
import { ACCESS_ITEMS, COURSE_SORT_OPTIONS } from '../../../data-structure/Enums';

import './Wrapper.scss';

const Wrapper: React.FC = (): React.JSX.Element => {
  // Get user context data
  const { user } = useContext(UserContext);

  // Get access permission
  const canSearchCourse = havePermission(ACCESS_ITEMS.CanSearchCourse, user?.userRole);

  return (
    <div className="course-component">
      <CreateCourse />
      <EditCourse />
      <FilterModal />
      <FilterBar itemName="Courses" canSearch={canSearchCourse} sortOptions={COURSE_SORT_OPTIONS}>
        <List />
      </FilterBar>
    </div>
  );
};

export default Wrapper;
