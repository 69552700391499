import React, { useContext } from 'react';

import { SearchContext } from '../FilterBar';
import type { EventTypes, TargetElementTypes } from '../../../data-structure/Types';

import './Search.scss';

const Search: React.FC = (): React.JSX.Element => {
  const { searchWord, setSearchWord } = useContext(SearchContext);

  const inputHandler = (e: EventTypes): void => {
    if ('target' in e) {
      const target = e.target as TargetElementTypes;

      setSearchWord({
        value: target.value,
      });
    }
  };

  return (
    <div className="wrapper">
      <div className="searchBar">
        <input
          id="searchQueryInput"
          type="text"
          value={searchWord?.value ?? ''}
          name="searchQueryInput"
          placeholder="Search"
          onChange={inputHandler}
          className="search-input"
        />
        {!searchWord?.value && (
          <button id="searchQuerySubmit" type="submit" name="searchQuerySubmit">
            <svg style={{ width: '24px', height: '24px' }} viewBox="0 0 24 24">
              <path
                fill="#192634"
                d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"
              />
            </svg>
          </button>
        )}
        {(searchWord?.value ?? '').length > 0 && (
          <button
            id="searchQueryRemove"
            type="button"
            name="searchQueryRemove"
            onClick={() => {
              setSearchWord(null);
            }}
          >
            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAv0lEQVR4nO2VXQrCMBAGxwtalZY86NkVLP6hN6hQCaQgJdXdsFHEDOQt+02TbBooFP6JBrgBB2ChqFsCJ+AK1FrpLBT2YXSAE9S5MHeo8xlqLk8BEvlY6sc5RbyKBE3JY9IuZCRRRwLvwGbUC7E561SpRJ5N+m4rpUdhvvJsK5XKs0qZONNYw31E2ueUu280V/Piykjuubl0wFxeGfwyfYaa1uCR2KeIjwbPos9QU4Uv3iq3zM/dhdp5irhQ+E0ekyummbane5EAAAAASUVORK5CYII=" />
          </button>
        )}
      </div>
    </div>
  );
};

export default Search;
