import React, { useState, createContext } from 'react';

import type {
  ICourse,
  ICourseContext,
  ICourseContextProviderProps,
} from '../data-structure/Interfaces';

export const CourseContext = createContext<ICourseContext | undefined>(undefined);

export const CourseContextProvider: React.FC<ICourseContextProviderProps> = ({
  children,
}): React.JSX.Element => {
  const [courses, setCourses] = useState<ICourse[]>();
  const [editingCourseId, setEditingCourseId] = useState<string>();

  return (
    <CourseContext.Provider value={{ courses, setCourses, editingCourseId, setEditingCourseId }}>
      {children}
    </CourseContext.Provider>
  );
};
